import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import vendorActions from '../../../../redux/Vendor/actions';
import userActions from '../../../../redux/users/actions';
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import TicketActions from '../../../../redux/Tickets/actions'
import { useDispatch, useSelector } from 'react-redux';
// import { PushNotification } from '../../../common/PushNotification';
import commonActions from '../../../../redux/Common/actions'

export default function EnterInputVendorCancel(props) {

    const dispatch = useDispatch();

    const { showHelpdeskVEnterInputPopup, handleCloseHelpdeskVEnterInputPopup, TicketDetails, LoggedIn_User,
        VendorDetails, OrderDetailsByTypeOfOrder, Ticket_Response } = props;

    const { register, formState: { errors }, handleSubmit, setValue, clearErrors, resetField } = useForm();

    const [loader, setLoader] = useState(false);
    const [abortReason, setAbortReason] = useState({
        Reason: '',
        Validation: false
    });

    const { VendorDetails2 } = useSelector(state => state.VendorReducer);

    const { UserDetails } = useSelector(state => state.registeredUserReducer);

    const handleClose = () => {
        handleCloseHelpdeskVEnterInputPopup();
        resetField('Input');
        resetField('Ticket_Approved_Status');
        setAbortReason({ Reason: '', Validation: false });


    }
    const handleTicketStatus = (e) => {
        setValue('Ticket_Approved_Status', e.target.value);
        clearErrors('Ticket_Approved_Status');
        if (e.target.value === 'Approved') {
            if (TicketDetails?.VendorID) {
                dispatch({ type: vendorActions.GET_VENDOR_DETAILS2, payload: TicketDetails?.VendorID });
            }
        } else if (e.target.value === 'Cancel and Refund Cash' || e.target.value === 'Cancel and Refund Coupon') {
            if (TicketDetails?.UserID) {
                dispatch({ type: userActions.GET_USER_DETAILS, payload: TicketDetails?.UserID })
            }
        };
    }

    const handleAbortReason = (e) => {
        if (e.target.value) {
            setAbortReason({ Reason: e.target.value, Validation: false });
        } else {
            setAbortReason({ Reason: '', Validation: true });
        };
    };

    const handleUpdate = (data) => {
        //Old Total - New Total
        if (OrderDetailsByTypeOfOrder?.Status !== 'Cancelled') {
            let TotalPriceDiff;
            if (OrderDetailsByTypeOfOrder?.CouponValue) {
                TotalPriceDiff = (parseFloat(OrderDetailsByTypeOfOrder?.Total) + parseFloat(OrderDetailsByTypeOfOrder?.CouponValue) - parseFloat(TicketDetails?.FinalCost))
            } else {
                TotalPriceDiff = (parseFloat(OrderDetailsByTypeOfOrder?.Total) - parseFloat(TicketDetails?.FinalCost))
            }
            let Refund_Status, Refund_Amount, Refund_Type;
            if (data.Ticket_Approved_Status === 'Approved') {
                Refund_Status = parseFloat(TotalPriceDiff) <= 0 ? "No Refund" : "Pending";
                Refund_Amount = parseFloat(TotalPriceDiff) <= 0 ? "0" : parseFloat(TotalPriceDiff);

            } else if (data.Ticket_Approved_Status === 'Cancel and Refund Cash') {
                Refund_Status = OrderDetailsByTypeOfOrder?.PaymentStatus === 'Paid' ? "Pending" : "Cash On Delivery";
                Refund_Amount = OrderDetailsByTypeOfOrder?.PaymentStatus === 'Paid' ?
                    OrderDetailsByTypeOfOrder?.Total : "0";
                Refund_Type = 'Cash'

            } else if (data.Ticket_Approved_Status === 'Cancel and Refund Coupon') {
                Refund_Status = OrderDetailsByTypeOfOrder?.PaymentStatus === 'Paid' ? "Pending" : "Cash On Delivery";
                Refund_Amount = OrderDetailsByTypeOfOrder?.PaymentStatus === 'Paid' ?
                    OrderDetailsByTypeOfOrder?.Total : "0";
                Refund_Type = 'Coupon'
            }
            if (data.Ticket_Approved_Status === 'Approved') {
                if (VendorDetails2 && VendorDetails2?.Notification_Id) {
                    let bodyContent;
                    (TicketDetails?.TypeOfOrder === 'Cake Order') ? bodyContent = 'You Received a New Cake Order' :
                        bodyContent = 'You Received a New Product Order';
                    let pushMessageData = {
                        type: 'vendor',
                        registration_ids: [VendorDetails2.Notification_Id],
                        title: 'Cakey',
                        body: bodyContent,
                    }

                    //New Push notification  
                    dispatch({ type: commonActions.SEND_PUSH_NOTIFICATION, payload: pushMessageData })

                    //Old push notification
                    // PushNotification(pushMessageData);
                };
            } else if (data.Ticket_Approved_Status === 'Cancel and Refund Cash' || data.Ticket_Approved_Status === 'Cancel and Refund Coupon') {
                if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
                    let bodyContent;
                    (TicketDetails?.TypeOfOrder === 'Cake Order') ? bodyContent = 'Your Order is Cancelled' :
                        bodyContent = 'Your Product Order is Cancelled';
                    let pushMessageData = {
                        type: 'customer',
                        registration_ids: [UserDetails.Notification_Id],
                        title: 'Cakey',
                        body: bodyContent,
                    }
                     //New Push notification  
                     dispatch({ type: commonActions.SEND_PUSH_NOTIFICATION, payload: pushMessageData })

                     //Old push notification
                    // PushNotification(pushMessageData);
                }
            }
            let Key, Value;
            if (TicketDetails?.PriceType === 'Price') {
                Key = "NewPrice";
                Value = TicketDetails?.NewPrice
            }
            else if (TicketDetails?.PriceType === 'PricePerUnit') {
                Key = "NewProductMinWeightPerUnit";
                Value = {
                    PricePerUnit: TicketDetails?.NewPrice,
                    ProductCount: OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.ProductCount,
                    Weight: OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.Weight,

                }
            } else if (TicketDetails?.PriceType === 'PricePerKg') {
                Key = "NewProductMinWeightPerKg";
                Value = {
                    PricePerKg: TicketDetails?.NewPrice,
                    Weight: OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.Weight
                }
            } else if (TicketDetails?.PriceType === 'PricePerBox') {
                Key = "NewProductMinWeightPerBox";
                Value = {
                    Piece: OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.Piece,
                    PricePerBox: TicketDetails?.NewPrice,
                    ProductCount: OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.ProductCount
                }
            }
            let Last_Intimate = ['HelpdeskV', 'Manager'];
            let Ticket_Intimation = TicketDetails?.Ticket_Accessed_By;
            for (let i = 0; i < Last_Intimate.length; i++) {
                let FieldName = Last_Intimate[i];
                Ticket_Intimation = {
                    ...Ticket_Intimation,
                    [FieldName]: 'y'
                };
            };
            let formData = {
                Id: TicketDetails?._id,
                data: {
                    HelpdeskC_Notes: data.Input,
                    Ticket_Approved_Status: data.Ticket_Approved_Status,
                    Ticket_Approved_Status_Updated_By: LoggedIn_User,
                    Refund_Status: Refund_Status,
                    Refund_Amount: Refund_Amount, // cost difference / 0

                    Refund_Type: (data.Ticket_Approved_Status === 'Cancel and Refund Coupon' || data.Ticket_Approved_Status === 'Cancel and Refund Cash') ? Refund_Type : '',
                    VendorID: VendorDetails?._id,
                    Vendor_ID: VendorDetails?.Id,
                    VendorName: VendorDetails?.VendorName,
                    VendorPhoneNumber1: VendorDetails?.PhoneNumber1,
                    VendorPhoneNumber2: VendorDetails?.PhoneNumber2,
                    VendorAddress: VendorDetails?.Address,
                    GoogleLocation: VendorDetails?.GoogleLocation,
                    TypeOfOrder: TicketDetails?.TypeOfOrder,
                    OrderID: TicketDetails?.OrderID,
                    NewTotal: TicketDetails?.FinalCost,
                    [Key]: Value,
                    NewTax: TicketDetails?.NewTax,
                    NewDiscount: TicketDetails?.NewDiscount,
                    NewExtraCharges: TicketDetails?.NewExtraCharges,
                    NewTopperCharges: TicketDetails?.NewTopperCharges,
                    NewDeliveryCharges: TicketDetails?.NewDeliveryCharges,
                    PriceType: TicketDetails?.PriceType,
                    Last_Intimate: Last_Intimate,
                    Ticket_Accessed_By: Ticket_Intimation,
                    FinalMargin: TicketDetails?.FinalMargin
                }
            }
            setLoader(true);
            dispatch({ type: TicketActions.HELPDESKV_VENDOR_CANCEL_HELPDESKC_NOTES, payload: formData })
            if (data.Ticket_Approved_Status === 'Approved') {
                if (TicketDetails?.VendorID) {
                    dispatch({ type: vendorActions.SET_VENDOR_DETAILS2, payload: [] });
                }
            } else if (data.Ticket_Approved_Status === 'Cancel and Refund Cash' || data.Ticket_Approved_Status === 'Cancel and Refund Coupon') {
                if (TicketDetails?.UserID) {
                    dispatch({ type: userActions.SET_USER_DETAILS, payload: [] });
                }
            };
        }
        // else if (OrderDetailsByTypeOfOrder?.Status === 'Cancelled') {

        // }

    }

    const handleAbortTicket = (e) => {
        e.preventDefault();
        if (abortReason?.Reason) {
            let formData = {
                Id: TicketDetails?._id,
                data: {
                    AbortReason: abortReason?.Reason,
                    Updated_By: LoggedIn_User,
                    Last_Intimate: ["HelpdeskV", "Manager"],
                    Ticket_Accessed_By: {
                        "HelpdeskV": "y",
                        "HelpdeskC": "y",
                        "Manager": "y",
                        "Management": "n",
                        "Accounts": "n",
                    },
                    Vendor_ID: OrderDetailsByTypeOfOrder?.Vendor_ID,
                    VendorID: OrderDetailsByTypeOfOrder?.VendorID,
                    VendorName: OrderDetailsByTypeOfOrder?.VendorName,
                }
            }
            setLoader(true);
            dispatch({ type: TicketActions.HELPDESKV_VENDOR_CANCEL_ABORT_REASON, payload: formData })
        } else {
            setAbortReason({ Reason: '', Validation: true });
        }
    };

    useEffect(() => {
        if (Ticket_Response?.statusCode === 200) {
            setLoader(false);
            resetField('Input');
            resetField('Ticket_Approved_Status');
            resetField('AbortReason');
            setAbortReason({ Reason: '', Validation: false });
        }
        //eslint-disable-next-line
    }, [Ticket_Response]);

    return (
        <div>
            <Modal show={showHelpdeskVEnterInputPopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">

                    <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Enter Inputs</h4>
                    {OrderDetailsByTypeOfOrder?.Status !== 'Cancelled' &&
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                {TicketDetails?.IsVendorAvailable === 'No Vendors' &&
                                    <>
                                        <label className='font-weight-bold'>Vendor Availability<span className='text-danger'>*</span></label>
                                        <div className="form-control">
                                            <span className='text-danger'>Vendors are not Available</span>
                                        </div>
                                    </>

                                }
                                <label className='float-left font-weight-bold mt-3' htmlFor="validationCustom18">Enter Notes<span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <textarea
                                        className={errors?.Input ? "form-control text-capitalize required-field" : "form-control text-capitalize"}
                                        type='text'
                                        placeholder="Enter Here..."
                                        {...register('Input', { required: { value: true, message: 'required' } })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Customer Approved Status<span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <select
                                        className={errors?.Ticket_Approved_Status ? "form-control required-field" : "form-control"}
                                        {...register('Ticket_Approved_Status', { required: { value: true, message: 'required' } })}
                                        defaultValue=''
                                        onChange={handleTicketStatus}

                                    >
                                        {TicketDetails?.IsVendorAvailable !== 'No Vendors' ? <>
                                            <option value='' disabled>--Select--</option>

                                            <option value='Approved'>Approved</option>
                                            <option value='Cancel and Refund Coupon'>Cancel and Refund Coupon</option>
                                            <option value='Cancel and Refund Cash'>Cancel and Refund Cash</option>
                                        </> :
                                            <>
                                                <option value='' disabled>--Select--</option>
                                                <option value='Cancel and Refund Cash'>Cancel and Refund Cash</option>
                                            </>

                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    }
                    {OrderDetailsByTypeOfOrder?.Status === 'Cancelled' &&
                        <>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Enter Reason for Abort<span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        <textarea
                                            className={abortReason?.Validation ? "form-control text-capitalize required-field" : "form-control text-capitalize"}
                                            type='text'
                                            placeholder="Enter Here..."
                                            onChange={handleAbortReason}
                                        // {...register('AbortReason', { required: { value: true, message: 'required' } })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div>
                        <p className="text-success intimation-message">This will be auto intimated to Vendor Helpdesk and Manager</p>
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            {OrderDetailsByTypeOfOrder?.Status === 'Cancelled' ?
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    onClick={handleAbortTicket}
                                >
                                    Update and Intimate
                                </button> :
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    onClick={handleSubmit(handleUpdate)}
                                >
                                    Update and Intimate
                                </button>
                            }
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}