// export const API_URL = 'https://cakey-database.vercel.app/api'
// export const API_URL = 'https://cakey-backend-alpha.vercel.app/api';
// export const API_URL = 'https://cakey-backend.herokuapp.com/api';
// export const API_URL = 'https://cakeybackend.onrender.com/api';
// export const API_URL = 'http://localhost:3001/api';

// export const API_URL = 'http://project2.sigmadigitec.com:3002/api'; //MM demo server
export const API_URL = 'https://sugitechnologies.com/cakey/api';

export const GOOGLE_API_KEY = 'AIzaSyAXMAB9YA1Uol4BR1aWZ1r1KoCw5W7AzbE';
export const CUSTOMER_PUSH_MESSAGE_KEY = 'Bearer AAAAfUzNhqs:APA91bEsu2OWHUz4U7Y2Y0Z3XpkBN0ePeyLEcBioYQd-UQdcr3pDjXvYfDcZaWrSExv-L-BfKBoAs6h10YMqRwNZZU7wrFmxsg8PvkpTtMw1PZEyxeH8Pd25vcKjEtFhhqriBMKcuIEj';
export const VENDOR_PUSH_MESSAGE_KEY = 'Bearer AAAAL2V35a4:APA91bFZWia2D2BIT4y0k89fEziuWzKUJdkZuL7t1wrDkMecslUYxs66XB4JmIFhLChd8O1GoHxW_01nkl1tdhItGfm6l8h05jMM_BKwF8KC_hbF2-R6e76KBVaAxUCPnv5sTBT-yWqJ';
