const actions = {
    SET_ALERT: 'SET_NOTIFICATION',
    SET_COMMON_ERRORS: 'SET_COMMON_ERRORS',
    SET_LOADER: 'SET_LOADER',
    SET_ONLINE_USERS: "SET_ONLINE_USERS",
    SET_BUFFER:"SET_BUFFER",
    SET_CURRENT_CONVERSATION:"SET_CURRENT_CONVERSATION",
    GET_INVOICE_CALCULATION: 'GET_INVOICE_CALCULATION',
    SET_INVOICE_CALCULATION: 'SET_INVOICE_CALCULATION',
    SET_FILE_VALIDATION: "SET_FILE_VALIDATION",
    SET_SOCKET_URL: 'SET_SOCKET_URL',
    SET_START_AND_END_DATE: 'SET_START_AND_END_DATE',
    SEND_PUSH_NOTIFICATION:'SEND_PUSH_NOTIFICATION'
}

export default actions;