import React, { useEffect, useState } from "react";
import { Modal, ResponsiveEmbed } from "react-bootstrap";
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import UserActions from '../../../../redux/users/actions';
import OtherActions from '../../../../redux/OtherProducts/actions';
import OrderActions from '../../../../redux/Order/actions';
import CommonActions from '../../../../redux/Common/actions';
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import Preloader from "../../../layouts/Preloader";
import Flavour from "./Flavour";
import Breadcrumb from "./Breadcrumb";
import { float2D } from "../../../../utils/Validation";
import FileSizeValidation from "../../../common/FileSizeValidation";
// import { PushNotification } from "../../../common/PushNotification";
import commonActions from '../../../../redux/Common/actions'

export default function Details() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.Id;

    const [loader, setLoader] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [reasonForCancel, setReasonForCancel] = useState("");

    let [show, setShow] = useState(false);
    let [show2, setShow2] = useState(false);
    let [showError, setShowError] = useState("");
    let [showErr, setShowErr] = useState('');
    let [UpdatedStatus, setUpdatedStatus] = useState("");
    let [selectedImage, setSelectedImage] = useState();
    let [selectedImageUrl, setSelectedImageUrl] = useState();

    const { OtherProductOrderDetails, UpdateStatusResponse, AcceptOrCancelStatus } = useSelector(state => state.OtherProductReducer);

    const { UserDetails } = useSelector(state => state.registeredUserReducer);

    const UserID = OtherProductOrderDetails?.UserID;

    //dispatch the get orders list api
    useEffect(() => {
        dispatch({ type: OtherActions.GET_OTHER_PRODUCT_ORDER_DETAILS, payload: Id });
        // eslint-disable-next-line
    }, [Id]);

    useEffect(() => {
        if (UserID) {
            dispatch({ type: UserActions.GET_USER_DETAILS, payload: UserID });
        }
        // eslint-disable-next-line
    }, [UserID]);

    useEffect(() => {
        if (OtherProductOrderDetails.length !== 0) {
            if (OtherProductOrderDetails.Vendor_Response_Status !== 'seen') {
                dispatch({
                    type: OrderActions.CHANGE_OTHERPRODUCT_ORDER_VIEW_RESPONSE_BY_VENDOR,
                    payload: { id: OtherProductOrderDetails._id, response: 'seen' }
                });
            }
        };
        // eslint-disable-next-line
    }, [OtherProductOrderDetails]);

    const ExistingStatus = OtherProductOrderDetails?.Status;
    useEffect(() => {
        (ExistingStatus === "Accepted") ? setUpdatedStatus('Preparing') :
            (ExistingStatus === "Preparing") ? setUpdatedStatus('Ready') :
                (ExistingStatus === "Ready") ? setUpdatedStatus('Out For Delivery') :
                    (ExistingStatus === "Out For Delivery") ? setUpdatedStatus('Delivered') :
                        setUpdatedStatus('');

    }, [ExistingStatus]);

    //open status change popup
    const handleShow = () => { setShow(true); };

    //open cancel order popup
    const handleShow2 = () => {
        setShow2(true);
        setShowErr(false);
    };

    //close status change popup
    const handleClose = () => {
        setShow(false);
        setShow2(false);
        setShowErr(false);
        setShowError('');
        setSelectedImage();
        setSelectedImageUrl();
        setUpdatedStatus("");
    };

    //close the page
    const handleCancel = (e) => {
        e.preventDefault();
        if (state.state.page === 'orderlist') {
            history.push("/Vendor-OrderList");
        } else if (state.state.page === 'reports') {
            history.push("/orders-report");
        }
    };

    const handleAddImage = (e) => {
        if (e.target.files.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setShowError('');
                setSelectedImage(selectedImage = result?.FinalImages[0]);
                if (selectedImage) {
                    setSelectedImageUrl(selectedImageUrl = URL.createObjectURL(selectedImage));
                }
            }
        }
    };

    //update order status functionality
    const handleUpdateStatus = (e) => {
        e.preventDefault();
        if (UpdatedStatus === "" || UpdatedStatus === undefined || UpdatedStatus === null) {
            setShowError("Please select the Status");
        } else {
            setLoader(true);
            setShowError("");
            if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
                let pushMessageData = {
                    type: 'customer',
                    registration_ids: [UserDetails.Notification_Id],
                    title: 'Cakey',
                    body: `Your Order Status - ${UpdatedStatus}`,
                }
                //New Push notification  
                dispatch({ type: commonActions.SEND_PUSH_NOTIFICATION, payload: pushMessageData })

                //old push notification
                // PushNotification(pushMessageData);
            }
            if (UpdatedStatus?.toLowerCase() === 'ready') {
                if (selectedImage) {
                    const formData = new FormData();
                    formData.append('Status', UpdatedStatus);
                    formData.append('file', selectedImage);
                    formData.append('Status_Updated_By', OtherProductOrderDetails?.VendorID);
                    dispatch({
                        type: OtherActions.UPDATE_OTHER_PRODUCT_ORDER_STATUS,
                        payload: { id: OtherProductOrderDetails?._id, data: formData }
                    });
                } else {
                    setShowError("Please Upload the Image");
                    setLoader(false);
                }
            } else {
                const formData = new FormData();
                formData.append('Status', UpdatedStatus);
                formData.append('Status_Updated_By', OtherProductOrderDetails?.VendorID);
                dispatch({
                    type: OtherActions.UPDATE_OTHER_PRODUCT_ORDER_STATUS,
                    payload: { id: OtherProductOrderDetails?._id, data: formData }
                });
            }
        }
    };

    // //validate update order status 
    useEffect(() => {
        if (UpdateStatusResponse?.statusCode === 200 || UpdateStatusResponse?.statusCode === 400) {
            setLoader(false);
            setShow(false);
            setShowError('');
            setSelectedImage();
            setSelectedImageUrl();
            setUpdatedStatus("");
        };
        // eslint-disable-next-line
    }, [UpdateStatusResponse]);

    const handleReasonForCancel = (e) => {
        setReasonForCancel(e.target.value);
        (e.target.value?.length > 0) ? setShowErr(false) : setShowErr(true);
    };

    const handleAcceptOrCancelOrder = (e) => {
        e.preventDefault();
        let formData;
        if (e.target.value === 'Accepted') {
            if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
                let pushMessageData = {
                    type: 'customer',
                    registration_ids: [UserDetails.Notification_Id],
                    title: 'Cakey',
                    body: 'Your Product Order Accepted',
                }
                //New Push notification  
                dispatch({ type: commonActions.SEND_PUSH_NOTIFICATION, payload: pushMessageData })

                //old push notification
                // PushNotification(pushMessageData);
            }
            formData = {
                Status: 'Accepted',
                Status_Updated_By: OtherProductOrderDetails?.VendorID
            };
            dispatch({
                type: OtherActions.ACCEPT_OR_CANCEL_OTHER_PRODUCT_ORDER,
                payload: { id: OtherProductOrderDetails?._id, data: formData }
            });
        } else {
            if (!reasonForCancel) {
                setShowErr(true);
            } else {
                setShowErr(false);
                setLoader(true);
                formData = {
                    Status: 'Rejected',
                    ReasonForCancel: reasonForCancel,
                    Cancelled_By: 'Vendor',
                    Status_Updated_By: OtherProductOrderDetails?.VendorID
                };
                dispatch({
                    type: OtherActions.ACCEPT_OR_CANCEL_OTHER_PRODUCT_ORDER,
                    payload: { id: OtherProductOrderDetails?._id, data: formData }
                });
            }
        }
    };

    useEffect(() => {
        if (AcceptOrCancelStatus?.statusCode === 200 || AcceptOrCancelStatus?.statusCode === 400) {
            setShowErr(false);
            setShow2(false);
            setLoader(false);
        };
        // eslint-disable-next-line
    }, [AcceptOrCancelStatus]);

    //loading
    useEffect(() => {
        (OtherProductOrderDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [OtherProductOrderDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb page={state?.state?.page} />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Vendor Order Details</h6>
                                    <span className="float-right add-banner-button">
                                        <button
                                            className="btn btn-success add-button rounded"
                                            disabled={(OtherProductOrderDetails?.Status === 'New' || OtherProductOrderDetails?.Status === 'Assigned') ? false : true}
                                            type="submit"
                                            value='Accepted'
                                            onClick={handleAcceptOrCancelOrder}
                                        >
                                            Accept Order
                                        </button>
                                    </span>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                {OtherProductOrderDetails?.Image &&
                                    <div className="ms-panel-body order-image tabledata-background">
                                        <ResponsiveEmbed aspectRatio='21by9'>
                                            <img
                                                className="d-block align-center responsive-image-width"
                                                src={OtherProductOrderDetails?.Image}
                                                alt="Cake"
                                            />
                                        </ResponsiveEmbed>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRODUCT INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {(OtherProductOrderDetails?.ReasonForCancel && (OtherProductOrderDetails?.Status === 'Rejected' || OtherProductOrderDetails?.Status === 'Cancelled')) &&
                                                <tr className="text-danger">
                                                    <th scope="row">Reason for Cancel</th>
                                                    <td>{OtherProductOrderDetails?.ReasonForCancel}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Order ID</th>
                                                <td><b>{OtherProductOrderDetails?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product ID</th>
                                                <td><b>{OtherProductOrderDetails?.Other_Product_ID}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product Name</th>
                                                <td>{OtherProductOrderDetails?.ProductName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product Common Name</th>
                                                <td>{OtherProductOrderDetails?.ProductCommonName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product Type</th>
                                                <td>{OtherProductOrderDetails?.CakeType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product SubType</th>
                                                <td><b>{OtherProductOrderDetails?.CakeSubType === 'macaroons' ? 'Macarons' : OtherProductOrderDetails?.CakeSubType}</b></td>
                                            </tr>
                                            {OtherProductOrderDetails?.EggOrEggless &&
                                                <tr>
                                                    <th scope="row">Egg or Eggless</th>
                                                    <td>{OtherProductOrderDetails?.EggOrEggless}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Flavour</th>
                                                <td><Flavour FlavoursList={OtherProductOrderDetails?.Flavour} /></td>
                                            </tr>
                                            {OtherProductOrderDetails?.Shape &&
                                                <tr>
                                                    <th scope="row">Shape</th>
                                                    <td>{OtherProductOrderDetails?.Shape}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Ordered Date</th>
                                                <td>{OtherProductOrderDetails?.Created_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(OtherProductOrderDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{OtherProductOrderDetails?.Status}</span></td>
                                            </tr>
                                            {OtherProductOrderDetails?.Status_Updated_On ?
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{OtherProductOrderDetails?.Status_Updated_On}</td>
                                                </tr> :
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{OtherProductOrderDetails?.Created_On}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {OtherProductOrderDetails?.Toppers?.TopperPrice &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">TOPPER INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Topper Name</th>
                                                    <td>{OtherProductOrderDetails?.Toppers?.TopperName}</td>
                                                </tr>
                                                {/* <tr>
                                                    <th scope="row">Topper Price</th>
                                                    <td>Rs. {OtherProductOrderDetails?.NewTopperCharges ? float2D(OtherProductOrderDetails?.NewTopperCharges) : float2D(OtherProductOrderDetails?.Toppers?.TopperPrice)}</td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="ms-panel-body tabledata-background">
                                        <ResponsiveEmbed aspectRatio='21by9'>
                                            <img
                                                className="d-block align-center w-50 h-50 responsive-image-width"
                                                src={OtherProductOrderDetails?.Toppers?.TopperImage}
                                                alt="First slide"
                                            />
                                        </ResponsiveEmbed>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRICE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {OtherProductOrderDetails?.ItemCount &&
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{OtherProductOrderDetails?.ItemCount}</td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerKg?.Weight &&
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{OtherProductOrderDetails?.ProductMinWeightPerKg?.Weight}</td>
                                                </tr>
                                            }
                                            {(OtherProductOrderDetails?.ProductMinWeightPerKg?.PricePerKg) &&
                                                <tr>
                                                    <th scope="row">Price per Kg</th>
                                                    <td><span>{OtherProductOrderDetails?.NewProductMinWeightPerKg?.PricePerKg ? float2D(OtherProductOrderDetails?.NewProductMinWeightPerKg?.PricePerKg) : float2D(OtherProductOrderDetails?.ProductMinWeightPerKg?.PricePerKg)}</span></td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerUnit?.Weight &&
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{OtherProductOrderDetails?.ProductMinWeightPerUnit?.Weight}</td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerUnit?.ProductCount &&
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{OtherProductOrderDetails?.ProductMinWeightPerUnit?.ProductCount} Units</td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerUnit?.PricePerUnit &&
                                                <tr>
                                                    <th scope="row">Price per Unit</th>
                                                    <td><span>{OtherProductOrderDetails?.NewProductMinWeightPerUnit?.PricePerUnit ? float2D(OtherProductOrderDetails?.NewProductMinWeightPerUnit?.PricePerUnit) : float2D(OtherProductOrderDetails?.ProductMinWeightPerUnit?.PricePerUnit)}</span></td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerBox?.Piece &&
                                                <tr>
                                                    <th scope="row">Pieces per Box</th>
                                                    <td>{OtherProductOrderDetails?.ProductMinWeightPerBox?.Piece} Pieces</td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerBox?.ProductCount &&
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{OtherProductOrderDetails?.ProductMinWeightPerBox?.ProductCount} Box</td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerBox?.PricePerBox &&
                                                <tr>
                                                    <th scope="row">Price per Box</th>
                                                    <td><span>{OtherProductOrderDetails?.NewProductMinWeightPerBox?.PricePerBox ? float2D(OtherProductOrderDetails?.NewProductMinWeightPerBox?.PricePerBox) : float2D(OtherProductOrderDetails?.ProductMinWeightPerBox?.PricePerBox)}</span></td>
                                                </tr>
                                            }
                                            {(OtherProductOrderDetails?.NewTopperCharges || OtherProductOrderDetails?.Toppers?.TopperPrice) &&
                                                <tr>
                                                    <th scope="row">Topper Price</th>
                                                    <td>{OtherProductOrderDetails?.NewTopperCharges ? float2D(OtherProductOrderDetails?.NewTopperCharges) : float2D(OtherProductOrderDetails?.Toppers?.TopperPrice)}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td><span>{OtherProductOrderDetails?.NewDiscount ? float2D(OtherProductOrderDetails?.NewDiscount) : float2D(OtherProductOrderDetails?.Discount)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Charge</th>
                                                <td><span>{OtherProductOrderDetails?.NewDeliveryCharges ? float2D(OtherProductOrderDetails?.NewDeliveryCharges) : float2D(OtherProductOrderDetails?.DeliveryCharge)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Total</th>
                                                <td>
                                                    <span><b>{OtherProductOrderDetails?.NewTotal ? (float2D(parseFloat(OtherProductOrderDetails?.NewTotal) - parseFloat(OtherProductOrderDetails?.NewTax))) : (float2D(parseFloat(OtherProductOrderDetails?.Total) + parseFloat(OtherProductOrderDetails?.CouponValue || 0) - (parseFloat(OtherProductOrderDetails?.Gst) + parseFloat(OtherProductOrderDetails?.Sgst))))}</b></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {OtherProductOrderDetails?.FinalProductImage &&
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h6 className='text-uppercase font-weight-bold'>FINAL PRODUCT IMAGE</h6>
                                    </div>
                                    <div className='header-break-line'> <hr /> </div>
                                    <div className="col-md-12">
                                        <div className="ms-panel">
                                            <div className="ms-panel-body order-image tabledata-background">
                                                <ResponsiveEmbed aspectRatio='21by9'>
                                                    <img
                                                        className="d-block align-center responsive-image-width"
                                                        src={OtherProductOrderDetails?.FinalProductImage}
                                                        alt="Cake"
                                                    />
                                                </ResponsiveEmbed>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">DELIVERY INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Delivery Information</th>
                                                <td>{OtherProductOrderDetails?.DeliveryInformation}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Date</th>
                                                <td>{OtherProductOrderDetails?.DeliveryDate}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Session</th>
                                                <td>{OtherProductOrderDetails?.DeliverySession}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Type</th>
                                                <td>{OtherProductOrderDetails?.PaymentType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Status</th>
                                                <td>{OtherProductOrderDetails?.PaymentStatus}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CUSTOMER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Customer ID</th>
                                                <td>{OtherProductOrderDetails?.User_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Name</th>
                                                <td>{OtherProductOrderDetails?.UserName}</td>
                                            </tr>
                                            {/* <tr>
                                                <th scope="row">Customer Phonenumber</th>
                                                <td>{OtherProductOrderDetails?.UserPhoneNumber}</td>
                                            </tr> */}
                                            {OtherProductOrderDetails?.DeliveryAddress &&
                                                <tr>
                                                    <th scope="row">Delivery Address</th>
                                                    <td><span className="long-line-break">{OtherProductOrderDetails?.DeliveryAddress}</span></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRODUCT DESCRIPTION</h4>
                                    <hr />
                                    <p>{OtherProductOrderDetails?.Description}</p>

                                    {OtherProductOrderDetails?.Status === 'Preparing' || OtherProductOrderDetails?.Status === 'Accepted' ||
                                        OtherProductOrderDetails?.Status === 'Ready' || OtherProductOrderDetails?.Status === 'Out For Delivery' ?
                                        <button
                                            className="btn btn-primary float-start mr-3"
                                            type="submit"
                                            onClick={handleShow}
                                        >
                                            Update Status
                                        </button> :
                                        <></>

                                    }
                                    {OtherProductOrderDetails?.Status === 'New' || OtherProductOrderDetails?.Status === 'Accepted' ||
                                        OtherProductOrderDetails?.Status === 'Preparing' ?
                                        <>
                                            {(!OtherProductOrderDetails?.NewTotal) &&
                                                <button
                                                    className="btn btn-warning float-start mr-3"
                                                    type="submit"
                                                    onClick={handleShow2}
                                                >
                                                    Cancel Order
                                                </button>
                                            }
                                        </>
                                        : <></>
                                    }
                                    <button
                                        className="btn btn-secondary float-start"
                                        type="submit"
                                        onClick={handleCancel}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* update order status popup  */}
                    <Modal className="modal-min" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        {/* {OtherProductOrderDetails?.Status === "Accepted" || OtherProductOrderDetails?.Status === "Ready" ||
                            OtherProductOrderDetails?.Status === "Out For Delivery" ? */}
                        <Modal.Body className="tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center">Update Order Status</h1>
                            <div>
                                <div className="form-row">
                                    {/* <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom23" className='font-weight-bold'>Status</label>
                                        <div className="input-group">
                                            <select className="form-control" id="validationCustom23" defaultValue={OtherProductOrderDetails?.Status} onChange={(e) => setUpdatedStatus(e.target.value)} required>
                                                {OtherProductOrderDetails?.Status === "Accepted" ?
                                                    <>
                                                        <option value="Accepted">New</option>
                                                        <option value="Preparing">Preparing</option>
                                                    </> : null
                                                }
                                                {OtherProductOrderDetails?.Status === "Preparing" ?
                                                    <>
                                                        <option value="Preparing">Preparing</option>
                                                        <option value="Ready">Ready</option>
                                                    </> : null
                                                }
                                                {OtherProductOrderDetails?.Status === "Ready" ?
                                                    <>
                                                        <option value="Ready">Ready</option>
                                                        <option value="Out For Delivery">Out For Delivery</option>
                                                    </> : null
                                                }
                                                {OtherProductOrderDetails?.Status === "Out For Delivery" ?
                                                    <>
                                                        <option value="Out For Delivery">Out For Delivery</option>
                                                        <option value="Delivered">Delivered</option>
                                                    </> : null
                                                }
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom23" className='font-weight-bold'>Current Status</label>
                                        <div className="input-group">
                                            <div className="form-control">{OtherProductOrderDetails?.Status}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom23" className='font-weight-bold'>New Status</label>
                                        <div className="input-group">
                                            {OtherProductOrderDetails?.Status === "Accepted" ?
                                                <div className="form-control">Preparing</div> :
                                                OtherProductOrderDetails?.Status === "Preparing" ?
                                                    <div className="form-control">Ready</div> :
                                                    OtherProductOrderDetails?.Status === "Ready" ?
                                                        <div className="form-control">Out For Delivery</div> :
                                                        OtherProductOrderDetails?.Status === "Out For Delivery" ?
                                                            <div className="form-control">Delivered</div> :
                                                            <></>
                                            }
                                        </div>
                                    </div>
                                    {OtherProductOrderDetails?.Status === "Preparing" &&
                                        <>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="validationCustom12" className='float-left font-weight-bold ml-2'> If order is ready you must upload a photo <span className='text-danger'>*</span></label>
                                                <div className="custom-file">
                                                    <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" onChange={handleAddImage} />
                                                    <label className="custom-file-label">Upload Image...</label>
                                                </div>
                                                <p className='text-success font-weight-bold float-left mt-1'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                            </div>
                                            <div className='display-flex'>
                                                <div className='display-flex'>
                                                    {selectedImageUrl !== undefined &&
                                                        <div className="imgPre">
                                                            <img
                                                                width='90px'
                                                                className='update-select-cake'
                                                                src={selectedImageUrl}
                                                                alt="Cake"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="text-danger">{showError}</div>
                                {loader ?
                                    <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary shadow-none mr-3"
                                            onClick={handleUpdateStatus}
                                        >
                                            Update
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary shadow-none"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </button>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* cencel order popup  */}
                    <Modal className="modal-min" show={show2} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center mb-3">Cancel Order</h1>
                            <div>
                                <label htmlFor="validationCustom24" className='font-weight-bold'>Reason For Cancel the Order - (Order Id : {OtherProductOrderDetails?.Id})<span className='text-danger'> *</span></label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className={showErr ? "required-field form-control text-capitalize" : "form-control text-capitalize"}
                                        name='ReasonForSuspend'
                                        id="validationCustom24"
                                        placeholder="Enter the reason..."
                                        onChange={handleReasonForCancel}
                                    />
                                    <p className='text-danger'>{showErr}</p>
                                </div>
                                {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary shadow-none"
                                            value='Cancelled'
                                            onClick={handleAcceptOrCancelOrder}
                                        >
                                            Cancel Order
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary ml-4"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </button>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    )
}