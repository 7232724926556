import { takeEvery, call, put, all } from 'redux-saga/effects';
import actions from './actions'
import { API_URL } from '../../utils/constants';
import axios from 'axios';

const CommonSaga = function* () {

    yield all([
        yield takeEvery(actions.GET_INVOICE_CALCULATION, GetInvoiceCalculation),
        yield takeEvery(actions.SEND_PUSH_NOTIFICATION, SendPushNotification),
    ]);
};

const GetInvoiceCalculation = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/orders/invoiceCalculation/${payload.type}`, payload.data
            )
        );
        yield put({
            type: actions.SET_INVOICE_CALCULATION,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const SendPushNotification = function* (data) {
    const { payload } = data
    console.log("inside api calling", payload)

    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/frontend/sendPushNotification`, payload)
        )
        console.log(result?.data)

    } catch (error) {
        console.log(error)
    }

}

export default CommonSaga;
