import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import VendorActions from '../../../../redux/Vendor/actions'
import OrderActions from '../../../../redux/Order/actions'
import { float2D, NumberValidation } from '../../../../utils/Validation';
import calculateDistance from '../../../common/DistanceCalculation';
import { GOOGLE_API_KEY } from '../../../../utils/constants';
import FlavourList from '../../../sections/ArrayMapping/FlavourList';

const customStyles = {
    menuList: base => ({
        ...base,
        maxHeight: "110px"
    })
}

export default function HelpdeskVUpdateCostDifferencePopup(props) {

    const { showhandleUpdateCostDifferencePopup, handleCloseCostDifferencePopup, ProductList,
        handleOrderAssignToVendor, Ticket_Response, TicketDetails, loader, NoVendor } = props;

    const dispatch = useDispatch();

    const [selectedProduct, setSelectedProduct] = useState();
    const [showDetails, setShowDetails] = useState(false);
    const [viewButtonValidate, setViewButtonValidate] = useState(false);
    const [viewFinalPrice, setViewFinalPrice] = useState(false);
    const [CostDifference, setCostDifference] = useState();

    let [pricePerKg, setPricePerKg] = useState();
    let [taxValue, setTaxValue] = useState();
    let [ExistingPrice, setExistingPrice] = useState();
    let [ExistingWeight, setExistingWeight] = useState();
    let [WeightForCalc, setWeightForCalc] = useState();
    let [finalTotal, setFinalTotal] = useState();
    let [newExtraCharges, setNewExtraCharges] = useState('0');
    let [newDeliveryCharges, setNewDeliveryCharges] = useState('0');
    let [newTopperCharges, setNewTopperCharges] = useState('0');
    let [FinalExtraCharges, setFinalExtraCharges] = useState('0');
    let [FinalMargin, setFinalMargin] = useState('0');
    let [TotalDistance, setTotalDistance] = useState('0');

    const { register, formState: { errors }, handleSubmit, setValue, resetField, reset } = useForm();

    const { OrderDetailsByTypeOfOrder } = useSelector(state => state.TicketReducer);

    const { VendorDetails } = useSelector(state => state.VendorReducer);

    const TypeOfOrder = TicketDetails?.TypeOfOrder;

    const { DeliveryCharge } = useSelector(state => state.OrderReducer);

    const { Tax } = useSelector(state => state.OrderReducer);

    useEffect(() => {
        dispatch({ type: OrderActions.GET_DELIVERY_CHARGE });
        // eslint-disable-next-line
    }, []);

    const handleselectedProduct = (e) => {
        setSelectedProduct(e);
        dispatch({
            type: VendorActions.GET_VENDOR_DETAILS_BY_ID,
            payload: { Id: e?.value?.VendorID }
        });
    };

    useEffect(() => {
        let NewCharge = 0;
        let Coordinates = {};
        const DeliveryChargeCalc = async () => {
            if (VendorDetails?.length !== 0) {
                let Distance = 0;
                if (OrderDetailsByTypeOfOrder?.DeliveryInformation === 'Delivery' && OrderDetailsByTypeOfOrder?.DeliveryAddress) {
                    await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${OrderDetailsByTypeOfOrder?.DeliveryAddress}&key=${GOOGLE_API_KEY}`)
                        .then((res) => res.json())
                        .then((data) => {
                            Coordinates = {
                                Latitude: data?.results[0]?.geometry?.location?.lat,
                                Longitude: data?.results[0]?.geometry?.location?.lng
                            };
                        }).catch(err => console.log(err));
                    Distance = calculateDistance(VendorDetails?.GoogleLocation?.Latitude, VendorDetails?.GoogleLocation?.Longitude,
                        Coordinates?.Latitude, Coordinates?.Longitude);
                    if (DeliveryCharge?.message === 'No Records Found') {
                        // setNewDeliveryCharges(newDeliveryCharges = 0);
                        NewCharge = 0;
                    } else {
                        setTotalDistance(Distance)
                        if (Distance > 2) {
                            let FinalDistance = Distance - 2;
                            let DistanceDividedByKm = parseFloat(FinalDistance) / parseFloat(DeliveryCharge[0]?.Km);
                            NewCharge = parseFloat(DistanceDividedByKm) * parseFloat(DeliveryCharge[0]?.Amount);
                        } else {
                            NewCharge = 0;
                        };
                    };
                } else {
                    NewCharge = 0;
                };
            }
            setNewDeliveryCharges((NewCharge).toString())
        }
        DeliveryChargeCalc();

    }, [VendorDetails, OrderDetailsByTypeOfOrder, DeliveryCharge])

    const handleClosePopup = (e) => {
        setSelectedProduct();
        setViewFinalPrice(false);
        setViewButtonValidate(false);
        handleCloseCostDifferencePopup(e);
        setCostDifference();
        setPricePerKg();
        setNewDeliveryCharges('0')
        setNewExtraCharges('0')
        setNewTopperCharges('0')
        setFinalTotal();
        setTaxValue();
        reset();

    };

    useEffect(() => {
        var Price, Weight;
        if (OrderDetailsByTypeOfOrder?.CakeSubType === "Brownie" ||
            OrderDetailsByTypeOfOrder?.CakeSubType === "Cookies" ||
            OrderDetailsByTypeOfOrder?.CakeSubType === "Chocolates" ||
            OrderDetailsByTypeOfOrder?.CakeSubType === 'Tea Cake' ||
            OrderDetailsByTypeOfOrder?.CakeSubType === 'Cheese Cake'
        ) {
            Price = OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.PricePerKg
            Weight = OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.Weight
        } else if (OrderDetailsByTypeOfOrder?.CakeSubType === "Cake Dessert") {
            Price = OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.PricePerUnit
            Weight = OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.ProductCount
        } else if (OrderDetailsByTypeOfOrder?.CakeSubType === "macaroons" ||
            OrderDetailsByTypeOfOrder?.CakeSubType === "Cup Cake"
        ) {
            Price = OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.PricePerBox
            Weight = OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.ProductCount
        } else if (TypeOfOrder === 'Cake Order') {
            Price = OrderDetailsByTypeOfOrder?.Price;
            Weight = OrderDetailsByTypeOfOrder?.Weight;
        }
        setExistingPrice(Price);
        setExistingWeight(Weight);

    }, [OrderDetailsByTypeOfOrder, TypeOfOrder])

    const FindCostDifference = (e) => {
        setPricePerKg(pricePerKg = e.target.value);
        setViewFinalPrice(false);
        if (pricePerKg) {
            let CostDifference = ExistingPrice - pricePerKg;
            setCostDifference(CostDifference);
            setValue('CostDifference', CostDifference);
            setValue('NewPrice', pricePerKg);
        } else {
            setCostDifference();
            setViewFinalPrice(false);
        }
        (e.target.value?.length === 0) ? setViewButtonValidate(true) : setViewButtonValidate(false);
    };

    const handleUpdate = (data) => {
        data['FinalMargin'] = FinalMargin;
        handleOrderAssignToVendor(VendorDetails, data)
    }

    const handleCalculateFinalTotal = (e) => {
        e.preventDefault();
        if (pricePerKg && newDeliveryCharges && newExtraCharges && newTopperCharges) {
            setViewFinalPrice(true);
            setViewButtonValidate(false);
            let IsKg;

            let ProductPrice, CakeTax, CakeFinalPrice, CakeWeight, ExtraChargeCalc;
            if (ExistingWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[1]) {
                IsKg = ExistingWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[1];
            }
            if (IsKg?.toLowerCase() === 'kg') {
                ProductPrice = (parseFloat(OrderDetailsByTypeOfOrder?.ItemCount || 1) * parseFloat(pricePerKg)) * parseFloat(ExistingWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]);
            } else if (IsKg?.toLowerCase() === 'g') {
                ProductPrice = (parseInt(OrderDetailsByTypeOfOrder?.ItemCount || 1) * parseFloat(pricePerKg)) * (parseFloat(ExistingWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0] / 1000));
            } else {
                ProductPrice = (parseInt(OrderDetailsByTypeOfOrder?.ItemCount || 1) * parseFloat(pricePerKg)) * parseFloat(ExistingWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]);
            }
            if (TypeOfOrder === 'Cake Order') {
                if (newExtraCharges) {
                    // ProductPrice += parseFloat(OrderDetailsByTypeOfOrder?.ExtraCharges);
                    ((ExistingWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[1])?.toLowerCase() === 'g') ? CakeWeight = [parseFloat(ExistingWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) / 1000] :
                        CakeWeight = ExistingWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi);
                    ExtraChargeCalc = parseFloat(newExtraCharges) * parseFloat(CakeWeight) * parseInt(OrderDetailsByTypeOfOrder?.ItemCount || 1)
                    ProductPrice += ExtraChargeCalc;
                    setFinalExtraCharges(FinalExtraCharges = ExtraChargeCalc);
                }
                if (OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice) {
                    if (newTopperCharges) {
                        ProductPrice += parseFloat(newTopperCharges);
                    }
                }
            } else if (TypeOfOrder === 'Product Order' && OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice !== null) {
                // if (OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice) {
                //     ProductPrice += parseFloat(OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice);
                // };
                if (newTopperCharges) {
                    ProductPrice += parseFloat(newTopperCharges);
                };
            }

            if (Tax?.length === 0) {
                setTaxValue(taxValue = 0);
            } else {
                if (parseFloat(Tax[0]?.Total_GST) > 0) {
                    // CakeTax = ((parseFloat(ProductPrice) + parseFloat(OrderDetailsByTypeOfOrder?.DeliveryCharge)) * parseFloat(Tax[0]?.Total_GST)) / 100;
                    // CakeTax = ((parseFloat(float2D(ProductPrice)) + parseFloat(float2D(newDeliveryCharges))) * parseFloat((Tax[0]?.Total_GST))) / 100;
                    //old calculation
                    // CakeTax = ((((parseFloat(float2D(ProductPrice)) + parseFloat(float2D(newDeliveryCharges))) * parseFloat((Tax[0]?.Total_GST))) / 100) / 2).toFixed(2);

                    //New tax change calculation
                    CakeTax = ((((parseFloat(float2D(ProductPrice))) * parseFloat((Tax[0]?.Total_GST) / 100)) * parseFloat((OrderDetailsByTypeOfOrder?.SharePercentage) / 100)) / 2).toFixed(2);
                } else {
                    CakeTax = 0;
                };
                setTaxValue(taxValue = CakeTax);
            };
            // CakeFinalPrice = parseFloat(ProductPrice) + parseFloat(taxValue) + parseFloat(OrderDetailsByTypeOfOrder?.DeliveryCharge)- parseFloat(OrderDetailsByTypeOfOrder?.Discount);
            CakeFinalPrice = parseFloat(float2D(ProductPrice)) + parseFloat(float2D(taxValue)) + parseFloat(float2D(taxValue)) + parseFloat(float2D(newDeliveryCharges)) //here to avoid point diff, tax is divided and added twice
            let NewTax = parseFloat(taxValue) + parseFloat(taxValue);// SGST + CGST
            setFinalTotal(finalTotal = CakeFinalPrice);
            setValue('FinalCost', float2D(CakeFinalPrice));
            setValue('NewTax', NewTax);
            setValue('NewDiscount', 0);
            setValue('NewDeliveryCharges', float2D(newDeliveryCharges));
            setValue('NewExtraCharges', float2D(FinalExtraCharges));
            setValue('NewTopperCharges', float2D(newTopperCharges));
        } else {
            setViewButtonValidate(true);
            setViewFinalPrice(false);
        };
    };

    useEffect(() => {
        dispatch({ type: OrderActions.GET_TAX });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (Ticket_Response?.statusCode === 200) {
            setSelectedProduct();
            setShowDetails(false);
            setViewButtonValidate(false);
            setViewFinalPrice(false);
            setCostDifference();
            setPricePerKg();
            setTaxValue();
            setExistingPrice();
            setExistingWeight();
            setWeightForCalc();
            setFinalTotal();
            setNewExtraCharges('0');
            setNewDeliveryCharges('0');
            setNewTopperCharges('0')
            resetField('VendorCallConversation');
        }
        //eslint-disable-next-line
    }, [Ticket_Response]);

    const handleNewData = (e, type) => {
        if (type === 'NewDeliveryCharge') {
            setNewDeliveryCharges(e);
            setViewFinalPrice(false);
        } else if (type === 'TopperCharges') {
            setNewTopperCharges(e);
            setViewFinalPrice(false);
        } else if (type === 'NewExtraCharge') {
            setNewExtraCharges(e);
            setViewFinalPrice(false);
        }
    };

    const customFilter = (option, searchText) => {
        if (searchText) {
            // if (option.data.value.VendorAddress.toLowerCase().includes(searchText?.toLowerCase())) {
            if (option.data.value.VendorAddress?.toLowerCase().includes(searchText?.toLowerCase()) ||
                option.data.value.VendorName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                option.data.value.Id?.toLowerCase().includes(searchText?.toLowerCase()) ||
                option.data.value.ProductName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                option.data.value.CakeName?.toLowerCase().includes(searchText?.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        };
    };

    useEffect(() => {
        let CakeWeight = [];
        ((OrderDetailsByTypeOfOrder?.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[1])?.toLowerCase() === 'g') ? CakeWeight = [parseFloat(OrderDetailsByTypeOfOrder?.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) / 1000] :
            CakeWeight = OrderDetailsByTypeOfOrder?.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi);

        if (CakeWeight?.length > 0) {
            let FinalMargin = CakeWeight[0] * OrderDetailsByTypeOfOrder?.Margin?.Amount;
            setFinalMargin(FinalMargin);
            setWeightForCalc(CakeWeight[0]);
        }
    }, [OrderDetailsByTypeOfOrder])

    return (
        <div>
            <Modal show={showhandleUpdateCostDifferencePopup} onHide={handleClosePopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleClosePopup}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-4">Order Assign to Vendor</h4>
                    <div className="form-row">
                        <div className='col-md-12'>
                            {/* <div className="ms-panel ms-panel-fh"> */}
                            <div className="ms-panel-body tabledata-background">
                                <h4 className="section-title bold">Existing Product Details:</h4>
                                <table className="table ms-profile-information">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Product Name</th>
                                            <td><span className="long-line-break"><b>{TypeOfOrder === 'Cake Order' ? OrderDetailsByTypeOfOrder?.CakeName : OrderDetailsByTypeOfOrder?.ProductName}</b></span></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Vendor Name</th>
                                            <td><span className="long-line-break">{OrderDetailsByTypeOfOrder?.VendorName}</span></td>
                                        </tr>
                                        {TypeOfOrder === 'Cake Order' &&
                                            <>
                                                <tr>
                                                    <th scope="row">Egg Or Eggless</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.EggOrEggless}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Flavour</th>
                                                    <td><span className="long-line-break"><FlavourList FlavoursList={OrderDetailsByTypeOfOrder?.Flavour} /></span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Shape</th>
                                                    <td><span className="long-line-break">{`${OrderDetailsByTypeOfOrder?.Shape?.Name}-Additional Rs.${OrderDetailsByTypeOfOrder?.Shape?.Price}/Kg`}</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.Weight}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.ItemCount}</td>
                                                </tr>
                                                {OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake === 'Cakey' ?
                                                    <>
                                                        <tr>
                                                            <th scope="row">Price per Kg</th>
                                                            <td>{float2D(OrderDetailsByTypeOfOrder?.Vendor_Price)}</td>
                                                        </tr>
                                                        {OrderDetailsByTypeOfOrder?.Margin?.Percentage &&
                                                            <>
                                                                <tr>
                                                                    <th scope="row">Margin Percentage</th>
                                                                    <td>{`${OrderDetailsByTypeOfOrder?.Margin?.Percentage}%`}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Margin per Kg</th>
                                                                    <td>{`Rs. ${OrderDetailsByTypeOfOrder?.Margin?.Amount}`}</td>
                                                                </tr>
                                                            </>
                                                        }

                                                    </>
                                                    :
                                                    <tr>
                                                        <th scope="row">Price per Kg</th>
                                                        <td>{float2D(OrderDetailsByTypeOfOrder?.Price)}</td>
                                                    </tr>
                                                }

                                                <tr>
                                                    <th scope="row">Extra Charges</th>
                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.ExtraCharges)}</td>
                                                </tr>
                                                {OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice &&
                                                    <tr>
                                                        <th scope="row">Topper Charges</th>
                                                        <td>{float2D(OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice)}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="row">Delivery Charges</th>
                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Discount</th>
                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.Discount || 0)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Coupon Value</th>
                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.CouponValue || 0)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Delivery Information</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.DeliveryInformation}</td>
                                                </tr>
                                            </>
                                        }
                                        {TypeOfOrder === 'Product Order' &&
                                            <>
                                                <tr>
                                                    <th scope="row">Product Type</th>
                                                    <td><b>{OrderDetailsByTypeOfOrder?.CakeSubType === 'macaroons' ? 'Macarons' : OrderDetailsByTypeOfOrder?.CakeSubType}</b></td>
                                                </tr>
                                                {(OrderDetailsByTypeOfOrder?.CakeSubType === "Brownie" ||
                                                    OrderDetailsByTypeOfOrder?.CakeSubType === "Cookies" ||
                                                    OrderDetailsByTypeOfOrder?.CakeSubType === "Chocolates" ||
                                                    OrderDetailsByTypeOfOrder?.CakeSubType === 'Tea Cake' ||
                                                    OrderDetailsByTypeOfOrder?.CakeSubType === 'Cheese Cake') &&
                                                    <>
                                                        <tr>
                                                            <th scope="row">Weight</th>
                                                            <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.Weight}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Price per Kg</th>
                                                            <td>{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.PricePerKg)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Product Count</th>
                                                            <td>{OrderDetailsByTypeOfOrder?.ItemCount}</td>
                                                        </tr>
                                                    </>
                                                }
                                                {(OrderDetailsByTypeOfOrder?.CakeSubType === "Cake Dessert") &&
                                                    <>
                                                        <tr>
                                                            <th scope="row">Weight</th>
                                                            <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.Weight}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Price per Unit</th>
                                                            <td>{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.PricePerUnit)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Product Count</th>
                                                            <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.ProductCount}</td>
                                                        </tr>
                                                    </>
                                                }
                                                {(OrderDetailsByTypeOfOrder?.CakeSubType === "macaroons" ||
                                                    OrderDetailsByTypeOfOrder?.CakeSubType === "Cup Cake") &&
                                                    <>
                                                        <tr>
                                                            <th scope="row">Piece</th>
                                                            <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.Piece}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Price per Box</th>
                                                            <td>{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.PricePerBox)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Product Count</th>
                                                            <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.ProductCount}</td>
                                                        </tr>
                                                    </>
                                                }
                                                <tr>
                                                    <th scope="row">Delivery Charges</th>
                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Delivery Information</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.DeliveryInformation}</td>
                                                </tr>
                                                {OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice &&
                                                    <tr>
                                                        <th scope="row">Topper Charges</th>
                                                        <td>{float2D(OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice)}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="row">Delivery Charges</th>
                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge)}</td>
                                                </tr>
                                            </>
                                        }
                                    </tbody>
                                </table>
                                {/* </div> */}
                            </div>
                        </div>


                        <div className="col-md-12 mt-2">
                            <label className='float-left font-weight-bold'>Select Product<span className='text-danger'>* {NoVendor === 'No Vendors' ? '- (Vendors are not Available)' : ''}</span></label>
                        </div>
                        {(TypeOfOrder === 'Cake Order') &&
                            <div className='col-md-12 mb-2'>
                                (Product ID - Product Name - Vendor Name - EggOrEggless - PricePerKg )
                            </div>
                        }
                        {(OrderDetailsByTypeOfOrder?.CakeSubType === "Brownie" ||
                            OrderDetailsByTypeOfOrder?.CakeSubType === "Cookies" ||
                            OrderDetailsByTypeOfOrder?.CakeSubType === "Chocolates" ||
                            OrderDetailsByTypeOfOrder?.CakeSubType === 'Tea Cake' ||
                            OrderDetailsByTypeOfOrder?.CakeSubType === 'Cheese Cake') &&
                            <div className='col-md-12 mb-2'>
                                (Product ID - Product Name - Vendor Name - Weight - PricePerKg )
                            </div>
                        }
                        {(OrderDetailsByTypeOfOrder?.CakeSubType === "Cake Dessert") &&
                            <div className='col-md-12 mb-2'>
                                (Product ID - Product Name - Vendor Name - Weight - PricePerUnit )
                            </div>
                        }
                        {(OrderDetailsByTypeOfOrder?.CakeSubType === "macaroons" ||
                            OrderDetailsByTypeOfOrder?.CakeSubType === "Cup Cake") &&
                            <div className='col-md-12 mb-2'>
                                (Product ID - Product Name - Vendor Name - Piece - PricePerBox )
                            </div>
                        }
                        <div className="col-md-12 mb-5">
                            <Select
                                styles={customStyles}
                                options={ProductList}
                                closeMenuOnSelect={true}
                                isSearchable={true}
                                onChange={(e) => handleselectedProduct(e)}
                                value={selectedProduct}
                                filterOption={customFilter}
                            />
                        </div>
                        {selectedProduct &&
                            < p className='text-primary cursor' onClick={() => setShowDetails(!showDetails)}><u>{showDetails ? "Less details" : "Show more details"}</u></p>
                        }
                        {(selectedProduct && showDetails && VendorDetails) &&
                            <div className='col-md-12 mb-3'>
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">Selected Vendor's Details</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Vendor ID</th>
                                                    <td><b>{VendorDetails?.Id}</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Vendor Name</th>
                                                    <td><span className="long-line-break">{VendorDetails?.VendorName}</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Email</th>
                                                    <td><span className="long-line-break">{VendorDetails?.Email}</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Main Mobile Number</th>
                                                    <td>{VendorDetails?.PhoneNumber1}</td>
                                                </tr>
                                                {VendorDetails?.PhoneNumber2 &&
                                                    <tr>
                                                        <th scope="row">Alternative Mobile Number</th>
                                                        <td>{VendorDetails?.PhoneNumber2}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="row">Address</th>
                                                    <td><span className="long-line-break">{VendorDetails?.Address}</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Pincode</th>
                                                    <td><span className="long-line-break">{VendorDetails?.Pincode}</span></td>
                                                </tr>
                                                {VendorDetails?.GoogleLocation &&
                                                    <tr>
                                                        <th scope="row">Google Location</th>
                                                        <td><b>Latitude</b>:{VendorDetails?.GoogleLocation?.Latitude} <br /><b>Longitude</b>:{VendorDetails?.GoogleLocation?.Longitude}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {(selectedProduct) &&
                            <>
                                <div className="col-md-12 mb-1">
                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Enter Call Conversation Details<span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        <textarea
                                            className={errors?.VendorCallConversation ? "form-control text-capitalize required-field" : "form-control text-capitalize"}
                                            type='text'
                                            placeholder="Enter Here..."
                                            {...register('VendorCallConversation', { required: { value: true, message: 'required' } })}
                                        />
                                    </div>
                                </div>
                                {OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake !== 'Cakey' &&
                                    <>
                                        <div className="col-md-12 col-sm-12">
                                            {(OrderDetailsByTypeOfOrder?.CakeSubType === "Brownie" ||
                                                OrderDetailsByTypeOfOrder?.CakeSubType === "Cookies" ||
                                                OrderDetailsByTypeOfOrder?.CakeSubType === "Chocolates" ||
                                                OrderDetailsByTypeOfOrder?.CakeSubType === 'Tea Cake' ||
                                                OrderDetailsByTypeOfOrder?.CakeSubType === 'Cheese Cake' || TypeOfOrder === 'Cake Order') &&
                                                <label className='float-left font-weight-bold'>New Price per Kg (<span className="text-primary">Existing Price per Kg - Rs.{float2D(TypeOfOrder === 'Cake Order' ? OrderDetailsByTypeOfOrder?.Price
                                                    : (OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.PricePerKg && OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.PricePerKg))}</span>)<span className='text-danger'>*</span></label>
                                            }
                                            {(OrderDetailsByTypeOfOrder?.CakeSubType === "Cake Dessert") &&
                                                <label className='float-left font-weight-bold'>New Price per Unit (<span className="text-primary">Existing Price per Unit - Rs.{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.PricePerUnit && OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.PricePerUnit)}</span>)<span className='text-danger'>*</span></label>
                                            }
                                            {(OrderDetailsByTypeOfOrder?.CakeSubType === "macaroons" ||
                                                OrderDetailsByTypeOfOrder?.CakeSubType === "Cup Cake") &&
                                                <label className='float-left font-weight-bold'>New Price per Box (<span className="text-primary">Existing Price Per Box - Rs.{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.PricePerBox && OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.PricePerBox)}</span>)<span className='text-danger'>*</span></label>
                                            }
                                            <div className='input-group'>
                                                <input
                                                    type='number'
                                                    onWheel={() => document.activeElement.blur()}
                                                    className={viewButtonValidate ? 'form-control required-field' : 'form-control'}
                                                    min={0} maxLength={6}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value?.replace(/[^0-9]/, '')
                                                        if (e.target.value.length > e.target.maxLength) {
                                                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                        } else if (e.target.value < e.target.min) {
                                                            e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                        }
                                                    }}
                                                    onChange={FindCostDifference}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Cost Difference per Kg<span className='text-danger'> *</span></label>
                                            <div className="input-group">
                                                <div
                                                    className={CostDifference < 0 ? "form-control required-field" : "form-control"}
                                                >{CostDifference ? CostDifference : 0}</div>
                                            </div>
                                        </div>
                                        {TypeOfOrder === 'Cake Order' &&
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">New Extra Charges (<span className="text-success">Including flavours and Shapes Cost per Kg</span> <span className="text-primary">Existing Extra Charges per Kg - Rs.{float2D((parseFloat(OrderDetailsByTypeOfOrder?.ExtraCharges) / WeightForCalc) || 0)}</span> )<span className='text-danger'> *</span></label>
                                                <div className='input-group'>
                                                    <input
                                                        type='number'
                                                        onWheel={() => document.activeElement.blur()}
                                                        className={!newExtraCharges ? 'form-control required-field' : 'form-control'}
                                                        min={0} maxLength={6}
                                                        value={newExtraCharges}
                                                        defaultValue={OrderDetailsByTypeOfOrder?.ExtraCharges}
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value?.replace(/[^0-9]/, '')
                                                            if (e.target.value.length > e.target.maxLength) {
                                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                            } else if (e.target.value < e.target.min) {
                                                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                            }
                                                        }}
                                                        // onChange={(e) => setNewExtraCharges(e.target.value)}
                                                        onChange={(e) => handleNewData(e.target.value, "NewExtraCharge")}
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice &&
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Topper Price given by New Vendor (<span className="text-primary">Existing Topper Charges - Rs.{float2D(OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice || 0)}</span>)<span className='text-danger'> *</span></label>
                                                <div className='input-group'>
                                                    <input
                                                        type='number'
                                                        onWheel={() => document.activeElement.blur()}
                                                        className={!newTopperCharges ? 'form-control required-field' : 'form-control'}
                                                        min={0} maxLength={6}
                                                        value={newTopperCharges}
                                                        // defaultValue={OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice}
                                                        // defaultValue={199}
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value?.replace(/[^0-9]/, '')
                                                            if (e.target.value.length > e.target.maxLength) {
                                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                            } else if (e.target.value < e.target.min) {
                                                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                            }
                                                        }}
                                                        // onChange={(e) => setNewTopperCharges(e.target.value)}
                                                        onChange={(e) => handleNewData(e.target.value, "TopperCharges")}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className="col-md-12 mb-3">
                                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Delivery Charges given by New Vendor<span className='text-danger'> *</span> ({float2D(TotalDistance)} Km)</label>
                                            <div className='input-group'>
                                                <input
                                                    type='number'
                                                    onWheel={() => document.activeElement.blur()}
                                                    value={newDeliveryCharges}
                                                    disabled={(OrderDetailsByTypeOfOrder?.DeliveryInformation).toLowerCase() === 'pickup' ? true : false}
                                                    className={!newDeliveryCharges ? 'form-control required-field' : 'form-control'}
                                                    min={0} maxLength={6}
                                                    onInput={NumberValidation}
                                                    // onChange={(e) => setNewDeliveryCharges(e.target.value)}
                                                    onChange={(e) => handleNewData(e.target.value, "NewDeliveryCharge")}
                                                />
                                            </div>
                                        </div>
                                    </>}

                                {((viewFinalPrice && newDeliveryCharges && newExtraCharges && newTopperCharges) || OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake === 'Cakey') &&
                                    <>
                                        <div className="col-md-12 col-sm-12">
                                            <div className="ms-panel-body tabledata-background">
                                                <h4 className="bold text-center">Final Price</h4>
                                                <table className="table ms-profile-information">
                                                    <tbody>
                                                        <tr>
                                                            {(OrderDetailsByTypeOfOrder?.CakeSubType === "Brownie" ||
                                                                OrderDetailsByTypeOfOrder?.CakeSubType === "Cookies" ||
                                                                OrderDetailsByTypeOfOrder?.CakeSubType === "Chocolates" ||
                                                                OrderDetailsByTypeOfOrder?.CakeSubType === 'Tea Cake' ||
                                                                OrderDetailsByTypeOfOrder?.CakeSubType === 'Cheese Cake' || TypeOfOrder === 'Cake Order') &&
                                                                <th scope="row">Price per Kg</th>

                                                            }
                                                            {(OrderDetailsByTypeOfOrder?.CakeSubType === "Cake Dessert") &&
                                                                <th scope="row">Price per Unit</th>
                                                            }
                                                            {(OrderDetailsByTypeOfOrder?.CakeSubType === "macaroons" ||
                                                                OrderDetailsByTypeOfOrder?.CakeSubType === "Cup Cake") &&
                                                                <th scope="row">Price per Box</th>
                                                            }
                                                            {OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake === 'Cakey' ?
                                                                <td>{float2D(OrderDetailsByTypeOfOrder?.Vendor_Price)}</td>
                                                                :
                                                                <td>{float2D(pricePerKg)}</td>
                                                            }
                                                        </tr>
                                                        {TypeOfOrder === 'Cake Order' &&
                                                            <>
                                                                <tr>
                                                                    <th scope="row">Weight</th>
                                                                    <td>{OrderDetailsByTypeOfOrder?.Weight}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Product Count</th>
                                                                    <td>{OrderDetailsByTypeOfOrder?.ItemCount}</td>
                                                                </tr>
                                                            </>
                                                        }
                                                        {(OrderDetailsByTypeOfOrder?.CakeSubType === "Brownie" ||
                                                            OrderDetailsByTypeOfOrder?.CakeSubType === "Cookies" ||
                                                            OrderDetailsByTypeOfOrder?.CakeSubType === "Chocolates" ||
                                                            OrderDetailsByTypeOfOrder?.CakeSubType === 'Tea Cake' ||
                                                            OrderDetailsByTypeOfOrder?.CakeSubType === 'Cheese Cake') &&
                                                            <>
                                                                <tr>
                                                                    <th scope="row">Weight</th>
                                                                    <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.Weight}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Product Count</th>
                                                                    <td>{OrderDetailsByTypeOfOrder?.ItemCount}</td>

                                                                </tr>
                                                            </>
                                                        }
                                                        {(OrderDetailsByTypeOfOrder?.CakeSubType === "Cake Dessert") &&
                                                            <tr>
                                                                <th scope="row">Product Count</th>
                                                                <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.ProductCount}</td>
                                                            </tr>

                                                        }
                                                        {(OrderDetailsByTypeOfOrder?.CakeSubType === "macaroons" ||
                                                            OrderDetailsByTypeOfOrder?.CakeSubType === "Cup Cake") &&
                                                            <>
                                                                <tr>
                                                                    <th scope="row">Product Count</th>
                                                                    <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.ProductCount}</td>
                                                                </tr>
                                                            </>
                                                        }
                                                        {/* {OrderDetailsByTypeOfOrder?.ExtraCharges && */}
                                                        {(newExtraCharges && OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake !== 'Cakey') &&
                                                            <tr>
                                                                <th scope="row">Extra Charges</th>
                                                                {/* <td>{float2D(OrderDetailsByTypeOfOrder?.ExtraCharges) || 0} </td> */}
                                                                <td>{float2D(FinalExtraCharges)} </td>
                                                            </tr>
                                                        }
                                                        {(newTopperCharges && OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake !== 'Cakey') &&
                                                            <tr>
                                                                <th scope="row">Topper Charges</th>
                                                                <td>{float2D(newTopperCharges)}</td>
                                                            </tr>
                                                        }
                                                        {OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake !== 'Cakey' &&
                                                            <tr>
                                                                <th scope="row">Discount</th>
                                                                <td>{float2D(0)}</td>
                                                            </tr>

                                                        }
                                                        {(newDeliveryCharges && OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake !== 'Cakey') &&
                                                            <tr>
                                                                <th scope="row">Delivery Charges</th>
                                                                {/* <td>{float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge)}</td> */}
                                                                <td>{float2D(newDeliveryCharges)}</td>
                                                            </tr>
                                                        }

                                                        {OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake === 'Cakey' &&
                                                            <>

                                                                <tr>
                                                                    <th scope="row">Extra Charges</th>
                                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.ExtraCharges || 0)} </td>
                                                                </tr>
                                                                {OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice &&
                                                                    <tr>
                                                                        <th scope="row">Topper Charges</th>
                                                                        <td>{float2D(OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice)}</td>
                                                                    </tr>
                                                                }
                                                                <tr>
                                                                    <th scope="row">Margin</th>
                                                                    <td><span disabled className='mr-2 text-muted'>
                                                                        <small>{OrderDetailsByTypeOfOrder?.Margin?.Amount}/kg</small></span>
                                                                        {float2D(FinalMargin)}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Discount</th>
                                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.Discount || 0)}</td>
                                                                    {/* <td>{float2D(0)}</td> */}
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Delivery Charges</th>
                                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge)}</td>
                                                                </tr>
                                                            </>

                                                        }

                                                        {OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake !== 'Cakey' &&
                                                            <>
                                                                <tr>
                                                                    <th scope="row">CGST</th>
                                                                    {Tax?.length > 0 && parseFloat(Tax[0]?.Total_GST) > 0 ?
                                                                        <td><span disabled className='mr-2 text-muted'><small>{parseFloat(Tax[0]?.Total_GST) / 2}%</small></span> {float2D(parseFloat(taxValue))}</td> :
                                                                        <td>0</td>
                                                                    }
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">SGST</th>
                                                                    {Tax?.length > 0 && parseFloat(Tax[0]?.Total_GST) > 0 ?
                                                                        <td><span disabled className='mr-2 text-muted'><small>{parseFloat(Tax[0]?.Total_GST) / 2}%</small></span> {float2D(parseFloat(taxValue))}</td> :
                                                                        <td>0</td>
                                                                    }
                                                                </tr>
                                                            </>
                                                        }
                                                        {OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake === 'Cakey' &&
                                                            <>
                                                                <tr>
                                                                    <th scope="row">CGST</th>
                                                                    {Tax?.length > 0 && parseFloat(Tax[0]?.Total_GST) > 0 ?
                                                                        <td><span disabled className='mr-2 text-muted'><small>{parseFloat(Tax[0]?.Total_GST) / 2}%</small></span> {float2D(OrderDetailsByTypeOfOrder?.Gst)}</td> :
                                                                        <td>0</td>
                                                                    }
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">SGST</th>
                                                                    {Tax?.length > 0 && parseFloat(Tax[0]?.Total_GST) > 0 ?
                                                                        <td><span disabled className='mr-2 text-muted'><small>{parseFloat(Tax[0]?.Total_GST) / 2}%</small></span> {float2D(OrderDetailsByTypeOfOrder?.Sgst)}</td> :
                                                                        <td>0</td>
                                                                    }
                                                                </tr>
                                                            </>

                                                        }
                                                        {OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake === 'Cakey' ?
                                                            <tr>
                                                                <th scope="row">Total</th>
                                                                <td><b>{float2D(OrderDetailsByTypeOfOrder?.Total)}</b></td>
                                                            </tr> :
                                                            <tr>
                                                                <th scope="row">Total</th>
                                                                <td><b>{float2D(finalTotal)}</b></td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Cost Difference in Total<span className='text-danger'> *</span><span className='text-primary'> (Existing Total + CouponValue - New Total)</span></label>
                                            <div className="input-group">
                                                <div className="form-control">{float2D(parseFloat(float2D(OrderDetailsByTypeOfOrder?.Total)) + parseFloat(float2D(OrderDetailsByTypeOfOrder?.CouponValue)) - parseFloat(float2D(finalTotal)))}</div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {(!viewFinalPrice && CostDifference >= 0 && newDeliveryCharges && newExtraCharges && newTopperCharges) &&
                                    <div className="col-md-12 col-sm-12 mb-2">
                                        <p className='text-danger font-weight-bold cursor' onClick={handleCalculateFinalTotal}><u>Click here to View Total Cost</u></p>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <div>
                        <p className="text-success intimation-message">This will be auto intimated to Customer Helpdesk and Manager</p>
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">

                            <button
                                type="submit"
                                disabled={((selectedProduct && viewFinalPrice) || OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake === 'Cakey' || NoVendor === 'No Vendors') ? false : true}
                                className="btn btn-primary shadow-none mr-3"
                                // onClick={(data) =>handleSubmit(handleOrderAssignToVendor(data, selectedVendor))}
                                onClick={handleSubmit(handleUpdate)}
                            >
                                Submit
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClosePopup}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div >
    )
}