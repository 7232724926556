import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import axios from "axios";
import actions from "./actions";
import CommonActions from '../Common/actions';
import { API_URL } from "../../utils/constants";
import setAuthToken from "../../utils/setAuthToken";
import io from 'socket.io-client'

const AuthSaga = function* () {

    yield all([
        yield takeEvery(actions.LOG_IN, workersLogIn),
        yield takeEvery(actions.VERIFY_TOKEN, verifyToken),
        yield takeEvery(actions.SET_LAST_SEEN, SetLastSeen),
        yield takeEvery(actions.GET_ACTIVE_VENDORS, GetActiveVendors),
        yield takeEvery(actions.UPDATE_CLOCKIN_OR_CLOCKOUT, updateClockInOrClockOut),
        yield takeEvery(actions.GET_USERS_ACTIVE_TIME, getUsersActiveTime),
    ]);
};

//login validation
const workersLogIn = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/login/validate`, payload
            )
        );
        if (result.data.statusCode === 200) {
            sessionStorage.setItem('token', result.data.token);
            localStorage.setItem('token', result.data.token);
            setAuthToken(result.data.token);

            yield put({
                type: actions.VERIFY_TOKEN,
                payload: result.data.token
            });
            yield put({
                type: CommonActions.SET_SOCKET_URL,
                // payload: io("http://sugitechnologies.com:3001", { transports: ['websocket'], upgrade: false })
                payload: io("https://sugitechnologies.com:3001", { transports: ['websocket'], upgrade: false })
                // payload: io("http://localhost:3001", { transports: ['websocket'], upgrade: false })
                // payload: io("http://project2.sigmadigitec.com:3002", { transports: ['websocket'], upgrade: false })
            });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: 'Login Succeed',
                    status: 'success',
                }
            });

            if (result.data.type === 'admin') {
                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/dashboard", result: result.data }
                });
            } else if (result.data.type === "vendor") {
                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/vendordashboard", result: result.data }
                });
            } else if (result.data.type === "Helpdesk V") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/helpdeskVdashboard", result: result.data }
                });
            } else if (result.data.type === "Helpdesk C") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/Helpdesk-C-Dashboard", result: result.data }
                });
            } else if (result.data.type === "Management") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/Management-Dashboard", result: result.data }
                });
            } else if (result.data.type === "Accounts") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/Accounts-Dashboard", result: result.data }
                });
            } else if (result.data.type === "Manager") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/Manager-Dashboard", result: result.data }
                });
            } else if (result.data.type === "Screen O") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/Screen-O-Dashboard", result: result.data }
                });
            } else if (result.data.type === "Screen D") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/Screen-D-Dashboard", result: result.data }
                });
            }
        } else {
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: 'Failed to Login',
                    status: 'failed',
                }
            });
            yield put({
                type: actions.UPDATE_AUTH_DETAILS,
                payload: { isAuthenticated: false, result: result.data }
            });
        }
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        yield put({
            type: actions.UPDATE_AUTH_DETAILS,
            payload: { isAuthenticated: false }
        });
    };
};

//verify token for auto login
const verifyToken = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/verifytoken/${payload}`
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_SOCKET_URL,
                // payload: io("http://sugitechnologies.com:3001", { transports: ['websocket'], upgrade: false })
                payload: io("https://sugitechnologies.com:3001", { transports: ['websocket'], upgrade: false })
                // payload: io("http://localhost:3001", { transports: ['websocket'], upgrade: false })
                // payload: io("http://project2.sigmadigitec.com:3002", { transports: ['websocket'], upgrade: false })
            });
            if (result.data.type === 'admin') {
                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/dashboard", token: result.data }
                });
            } else if (result.data.type === "vendor") {
                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/vendordashboard", token: result.data }
                });
            } else if (result.data.type === "Helpdesk V") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/helpdeskVdashboard", token: result.data }
                });
            } else if (result.data.type === "Helpdesk C") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/Helpdesk-C-Dashboard", token: result.data }
                });
            } else if (result.data.type === "Management") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/Management-Dashboard", token: result.data }
                });
            } else if (result.data.type === "Accounts") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/Accounts-Dashboard", token: result.data }
                });
            } else if (result.data.type === "Manager") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/Manager-Dashboard", token: result.data }
                });
            } else if (result.data.type === "Screen O") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/Screen-O-Dashboard", token: result.data }
                });
            } else if (result.data.type === "Screen D") {

                yield put({
                    type: actions.UPDATE_AUTH_DETAILS,
                    payload: { isAuthenticated: true, route: "/Screen-D-Dashboard", token: result.data }
                });
            }
        } else if (result.data.message === "Invalid token") {
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
            window.location.reload();
            yield put({
                type: actions.UPDATE_AUTH_DETAILS,
                payload: { isAuthenticated: false }
            });
        }

    } catch (err) {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        window.location.reload();
        yield put({
            type: actions.UPDATE_AUTH_DETAILS,
            payload: { token: 'Failed' }
        });
    };
};

const SetLastSeen = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.put(
                `${API_URL}/lastseen`, payload
            )
        );

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetActiveVendors = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/vendor/activelist`
            )
        );
        yield put({
            type: actions.SET_ACTIVE_VENDORS,
            payload: { ActiveVendors: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const updateClockInOrClockOut = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/update/activeTime/${payload.Id}`, payload.data
            )
        );
        yield put({
            type: actions.GET_USERS_ACTIVE_TIME,
            payload: { Id: payload.Id, TypeOfUser: payload.data.TypeOfUser }
        });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result?.data?.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result?.data?.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const getUsersActiveTime = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/users/activeTimebyId/${payload.Id}/${payload.TypeOfUser}`
            )
        );
        yield put({
            type: actions.SET_USERS_ACTIVE_TIME,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

export default AuthSaga;