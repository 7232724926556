import React, { useEffect, useState } from "react";
import { Modal, ResponsiveEmbed } from "react-bootstrap";
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import UserActions from '../../../../redux/users/actions';
import HamperActions from '../../../../redux/HampersOrder/actions';
import CommonActions from '../../../../redux/Common/actions';
import OrderActions from '../../../../redux/Order/actions';
import Preloader from '../../../layouts/Preloader';
import ProductContains from "../../../vendor/section/Vendor-Hampers-Details/ProductContains";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import Breadcrumb from "./Breadcrumb";
import { float2D } from "../../../../utils/Validation";
import FileSizeValidation from "../../../common/FileSizeValidation";
// import { PushNotification } from "../../../common/PushNotification";
import commonActions from '../../../../redux/Common/actions'

export default function Details() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const OrderId = state?.state?.OrderId;

    const [loader, setLoader] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [reasonForCancel, setReasonForCancel] = useState("");

    let [show, setShow] = useState(false);
    let [show2, setShow2] = useState(false);
    let [showError, setShowError] = useState("");
    let [showErr, setShowErr] = useState('');
    let [UpdatedStatus, setUpdatedStatus] = useState("");
    let [selectedImage, setSelectedImage] = useState();
    let [selectedImageUrl, setSelectedImageUrl] = useState();

    //get logged in users details
    const auth = useSelector(state => state.authReducer);

    const { HamperDetails, HamperUpdateStatus, CancelHamperStatus } = useSelector(state => state.HamperOrderReducer);

    const { UserDetails } = useSelector(state => state.registeredUserReducer);

    const UserID = HamperDetails?.UserID;

    //dispatch the get orders list api
    useEffect(() => {
        dispatch({ type: HamperActions.GET_HAMPER_DETAILS, payload: OrderId });
        // eslint-disable-next-line
    }, [OrderId]);

    useEffect(() => {
        if (UserID) {
            dispatch({ type: UserActions.GET_USER_DETAILS, payload: UserID });
        }
        // eslint-disable-next-line
    }, [UserID]);

    useEffect(() => {
        if (HamperDetails.length !== 0) {
            if (HamperDetails.Vendor_Response_Status !== 'seen') {
                dispatch({
                    type: OrderActions.CHANGE_HAMPER_ORDER_VIEW_RESPONSE_BY_VENDOR,
                    payload: { id: HamperDetails._id, response: 'seen' }
                });
            }
        };
        // eslint-disable-next-line
    }, [HamperDetails]);

    const ExistingStatus = HamperDetails?.Status;
    useEffect(() => {
        (ExistingStatus === "Accepted") ? setUpdatedStatus('Preparing') :
            (ExistingStatus === "Preparing") ? setUpdatedStatus('Ready') :
                (ExistingStatus === "Ready") ? setUpdatedStatus('Out For Delivery') :
                    (ExistingStatus === "Out For Delivery") ? setUpdatedStatus('Delivered') :
                        setUpdatedStatus('');

    }, [ExistingStatus]);

    //open status change popup
    const handleShow = () => { setShow(true); };

    //open cancel order popup
    // const handleShow2 = () => {
    //     setShow2(true);
    //     setShowErr(false);
    // };

    //close status change popup
    const handleClose = () => {
        setShow(false);
        setShow2(false);
        setShowErr(false);
        setShowError('');
        setSelectedImage();
        setSelectedImageUrl();
        setUpdatedStatus("");
    };

    //for close the page
    function handleCancel() {
        if (state?.state?.page === 'list') {
            history.push("/vendor-hamper-orders-list");
        } else if (state.state.page === 'reports') {
            history.push("/orders-report");
        }
    };

    const handleAddImage = (e) => {
        if (e.target.files.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setShowError('');
                setSelectedImage(selectedImage = result?.FinalImages[0]);
                if (selectedImage) {
                    setSelectedImageUrl(selectedImageUrl = URL.createObjectURL(selectedImage));
                }
            }
        }
    };

    //update order status functionality
    const handleUpdateStatus = (e) => {
        e.preventDefault();
        if (UpdatedStatus === "" || UpdatedStatus === undefined || UpdatedStatus === null) {
            setShowError("Please select the Status");
        } else {
            setShowError("");
            if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
                let pushMessageData = {
                    type: 'customer',
                    registration_ids: [UserDetails.Notification_Id],
                    title: 'Cakey',
                    body: `Your Order Status - ${UpdatedStatus}`,
                }
                //New Push notification  
                dispatch({ type: commonActions.SEND_PUSH_NOTIFICATION, payload: pushMessageData })

                //old push notification
                // PushNotification(pushMessageData);
            }
            if (UpdatedStatus.toLowerCase() === 'ready') {
                if (selectedImage) {
                    const formData = new FormData();
                    formData.append('Status', UpdatedStatus);
                    formData.append('file', selectedImage);
                    setLoader(true);
                    dispatch({ type: HamperActions.UPDATE_HAMPER_ORDER_STATUS, payload: { data: formData, OrderId: HamperDetails?._id } })
                } else {
                    setShowError("Please Upload the Image");
                }
            } else {
                const formData = new FormData();
                formData.append('Status', UpdatedStatus);
                setLoader(true);
                dispatch({ type: HamperActions.UPDATE_HAMPER_ORDER_STATUS, payload: { data: formData, OrderId: HamperDetails?._id } })
            }
        }
    };

    const handleCancelOrder = (e) => {
        e.preventDefault();
        if (!reasonForCancel) {
            setShowErr(true);
        } else {
            setLoader(true);
            setShowErr(false);
            if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
                let pushMessageData = {
                    type: 'customer',
                    registration_ids: [UserDetails.Notification_Id],
                    title: 'Cakey',
                    body: 'Your Hamper Order is Cancelled',
                }
                //New Push notification  
                dispatch({ type: commonActions.SEND_PUSH_NOTIFICATION, payload: pushMessageData })

                //old push notification
                // PushNotification(pushMessageData);
            }
            dispatch({ type: HamperActions.CANCEL_HAMPER_ORDER, payload: { Id: HamperDetails?._id, ReasonForCancel: reasonForCancel } });
        }
    };

    //validate update order status 
    useEffect(() => {
        if (HamperUpdateStatus?.statusCode === 200 || HamperUpdateStatus?.statusCode === 400) {
            setLoader(false);
            setShow(false);
            setUpdatedStatus('');
            setSelectedImage();
            setSelectedImageUrl();
        };
        // eslint-disable-next-line
    }, [HamperUpdateStatus]);

    const handleAcceptOrder = (e) => {
        e.preventDefault();
        if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
            let pushMessageData = {
                type: 'customer',
                registration_ids: [UserDetails.Notification_Id],
                title: 'Cakey',
                body: 'Your Hamper Order Accepted',
            }
            //New Push notification  
            dispatch({ type: commonActions.SEND_PUSH_NOTIFICATION, payload: pushMessageData })

            //old push notification
            // PushNotification(pushMessageData);
        }
        dispatch({ type: HamperActions.ACCEPT_HAMPER_ORDER, payload: HamperDetails?._id });
    };

    useEffect(() => {
        if (CancelHamperStatus?.statusCode === 200 || CancelHamperStatus?.statusCode === 400) {
            setLoader(false);
            setShow2(false);
        }
        // eslint-disable-next-line
    }, [CancelHamperStatus, auth]);

    useEffect(() => {
        (HamperDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [HamperDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb page={state?.state?.page} />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel tabledata-background">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Vendor Hamper Order Details</h6>
                                    <span className="float-right add-banner-button">
                                        <button
                                            className="btn btn-success add-button rounded"
                                            disabled={HamperDetails?.Status === 'New' ? false : true}
                                            type="submit"
                                            onClick={handleAcceptOrder}
                                        >
                                            Accept Order
                                        </button>
                                    </span>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                {HamperDetails?.HamperImage &&
                                    <div className="ms-panel-body order-image">
                                        <ResponsiveEmbed aspectRatio='21by9'>
                                            <img className="d-block align-center responsive-image-width" src={HamperDetails?.HamperImage} alt="cake" />
                                        </ResponsiveEmbed>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">HAMPER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {HamperDetails?.ReasonForCancel &&
                                                <tr className="text-danger">
                                                    <th scope="row">Reason for Cancel</th>
                                                    <td>{HamperDetails?.ReasonForCancel}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Hamper ID</th>
                                                <td><b>{HamperDetails?.Hamper_ID}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Hamper Name</th>
                                                <td>{HamperDetails?.HampersName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Title</th>
                                                <td>{HamperDetails?.Title}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Weight</th>
                                                <td>{HamperDetails?.Weight}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Ordered Date</th>
                                                <td>{HamperDetails?.Created_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(HamperDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{HamperDetails?.Status}</span></td>
                                            </tr>
                                            {HamperDetails?.Status_Updated_On !== undefined ?
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{HamperDetails?.Status_Updated_On}</td>
                                                </tr> :
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{HamperDetails?.Created_On}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRICE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Product Count</th>
                                                <td>{HamperDetails?.ItemCount}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Price</th>
                                                <td><i className="fas fa-rupee-sign price-info" /><span>{float2D(HamperDetails?.Price)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Charge</th>
                                                <td>{HamperDetails?.DeliveryCharge !== '0' && <i className="fas fa-rupee-sign price-info" />}<span>{float2D(HamperDetails?.DeliveryCharge)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Total</th>
                                                <td>
                                                    <i className="fas fa-rupee-sign price-info" />
                                                    <span><b>{float2D((parseFloat(HamperDetails?.Total) + parseFloat(HamperDetails?.CouponValue || 0)) - (parseFloat(HamperDetails?.Gst) + parseFloat(HamperDetails?.Sgst)))}</b></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">DELIVERY INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Delivery Information</th>
                                                <td>{HamperDetails?.DeliveryInformation}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Date</th>
                                                <td>{HamperDetails?.DeliveryDate}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Session</th>
                                                <td>{HamperDetails?.DeliverySession}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Type</th>
                                                <td>{HamperDetails?.PaymentType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Status</th>
                                                <td>{HamperDetails?.PaymentStatus}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CUSTOMER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Customer ID</th>
                                                <td>{HamperDetails?.User_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Name</th>
                                                <td>{HamperDetails?.UserName}</td>
                                            </tr>
                                            {/* <tr>
                                                <th scope="row">Customer Phonenumber</th>
                                                <td>{HamperDetails?.UserPhoneNumber}</td>
                                            </tr> */}
                                            {HamperDetails?.DeliveryAddress &&
                                                <tr>
                                                    <th scope="row">Delivery Address</th>
                                                    <td>{HamperDetails?.DeliveryAddress}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CONTAINS</h4>
                                    <hr />
                                    <ProductContains ArrayList={HamperDetails?.Product_Contains} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">HAMPER DESCRIPTION</h4>
                                    <hr />
                                    <p>{HamperDetails?.Description}</p>

                                    {HamperDetails?.Status === 'New' || HamperDetails?.Status === 'Assigned' || HamperDetails?.Status === 'Preparing' ||
                                        HamperDetails?.Status === 'Accepted' || HamperDetails?.Status === 'Ready' || HamperDetails?.Status === 'Out For Delivery' ?
                                        <>
                                            {HamperDetails?.Status === 'Preparing' || HamperDetails?.Status === 'Accepted' ||
                                                HamperDetails?.Status === 'Ready' || HamperDetails?.Status === 'Out For Delivery' ?
                                                <button className="btn btn-primary float-start mr-3" type="submit" onClick={handleShow} >Update Status</button> : <></>
                                            }
                                            {/* <button className="btn btn-warning float-start mr-3" type="submit" onClick={handleShow2} >Cancel Order</button> */}
                                        </> :
                                        <></>
                                    }
                                    <button className="btn btn-secondary float-start" type="submit" onClick={handleCancel} >Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* update order status popup  */}
                    <Modal className="modal-min" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center">Update Order Status</h1>

                            <div>
                                <div className="form-row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom23" className='font-weight-bold'>Current Status</label>
                                        <div className="input-group">
                                            <div className="form-control">{HamperDetails?.Status}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom23" className='font-weight-bold'>New Status</label>
                                        <div className="input-group">
                                            {HamperDetails?.Status === "Accepted" ?
                                                <div className="form-control">Preparing</div> :
                                                HamperDetails?.Status === "Preparing" ?
                                                    <div className="form-control">Ready</div> :
                                                    HamperDetails?.Status === "Ready" ?
                                                        <div className="form-control">Out For Delivery</div> :
                                                        HamperDetails?.Status === "Out For Delivery" ?
                                                            <div className="form-control">Delivered</div> :
                                                            <></>
                                            }
                                        </div>
                                    </div>
                                    {HamperDetails?.Status === "Preparing" &&
                                        <>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="validationCustom12" className='float-left font-weight-bold ml-2'> If order is ready you must upload a photo <span className='text-danger'>*</span></label>
                                                <div className="custom-file">
                                                    <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" onChange={handleAddImage} />
                                                    <label className="custom-file-label">Upload Image...</label>
                                                </div>
                                                <p className='text-success font-weight-bold float-left mt-1'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                            </div>
                                            <div className='display-flex'>
                                                <div className='display-flex'>
                                                    {selectedImageUrl !== undefined &&
                                                        <div className="imgPre">
                                                            <img width='90px' className='update-select-cake' src={selectedImageUrl} alt="Cake" />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="text-danger">{showError}</div>
                                {loader ?
                                    <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary shadow-none mr-3"
                                            onClick={handleUpdateStatus}
                                        >
                                            Update
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary shadow-none"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </button>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* cencel order popup  */}
                    <Modal className="modal-min" show={show2} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center mb-3">Cancel Order</h1>
                            <div>
                                <label htmlFor="validationCustom24" className='font-weight-bold'>Reason For Cancel the Order - (Order Id : {HamperDetails?.Id})<span className='text-danger'> *</span></label>
                                <div className="input-group">
                                    <input type="text" className={showErr ? "required-field form-control text-capitalize" : "form-control text-capitalize"} name='ReasonForSuspend' id="validationCustom24" placeholder="Enter the reason..." onChange={(e) => setReasonForCancel(e.target.value)} />
                                    <p className='text-danger'>{showErr}</p>
                                </div>
                                {loader ?
                                    <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary shadow-none"
                                            onClick={handleCancelOrder}
                                        >
                                            Cancel Order
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary ml-4"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </button>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    )
};