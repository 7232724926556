import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import OrderActions from '../../../../redux/Order/actions';
import CommonActions from '../../../../redux/Common/actions';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import { float2D, NumberValidation } from '../../../../utils/Validation';
import calculateDistance from '../../../common/DistanceCalculation';
import { GOOGLE_API_KEY } from '../../../../utils/constants';

export default function HelpdeskVEditPricePopup(props) {

    const { showHelpdeskVEditPricePopup, handleCloseEditPricePopup, CustomizedCakeDeatils,
        handleCustomizedCakeEditedPrice, loader } = props;

    const dispatch = useDispatch();

    const [viewButtonValidate, setViewButtonValidate] = useState(false);
    const [viewFinalPrice, setViewFinalPrice] = useState(false);

    // let [coordinates, setCoordinates] = useState();
    // let [pricePerKg, setPricePerKg] = useState();
    // let [taxValue, setTaxValue] = useState();
    let [distance, setDistance] = useState(0);
    let [DeliveryChargeValue, setDeliveryChargeValue] = useState('');
    // let [finalTotal, setFinalTotal] = useState();

    const { Tax, DeliveryCharge,productShareperList } = useSelector(state => state.OrderReducer);

    const { Invoice_Calculation } = useSelector(state => state.commonReducer);

    // let DeliveryCharge = CustomizedCakeDeatils?.DeliveryCharge;

    useEffect(() => {
        async function FetchData() {
            // dispatch({ type: OrderActions.GET_TAX });
            let VendorDeliveryCharge = 0, coordinates = {};
            if (CustomizedCakeDeatils?.DeliveryInformation === 'Delivery' && CustomizedCakeDeatils?.DeliveryAddress) {
                await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${CustomizedCakeDeatils?.DeliveryAddress}&key=${GOOGLE_API_KEY}`)
                    .then((res) => res.json())
                    .then((data) => {
                        coordinates = {
                            Latitude: data.results[0].geometry.location.lat,
                            Longitude: data.results[0].geometry.location.lng
                        };
                    }).catch(err => console.log(err));
                const Distance = calculateDistance(CustomizedCakeDeatils?.GoogleLocation?.Latitude, CustomizedCakeDeatils?.GoogleLocation?.Longitude,
                    coordinates?.Latitude, coordinates?.Longitude);
                setDistance(Distance);
                if (DeliveryCharge?.message === 'No Records Found') {
                    VendorDeliveryCharge = 0;
                } else {
                    if (Distance > 2) {
                        let FinalDistance = Distance - 2;
                        let DistanceDividedByKm = parseFloat(FinalDistance) / parseInt(DeliveryCharge[0]?.Km);
                        VendorDeliveryCharge = parseFloat(DistanceDividedByKm) * parseInt(DeliveryCharge[0]?.Amount);
                    } else {
                        VendorDeliveryCharge = 0;
                    };
                };
            } else {
                VendorDeliveryCharge = 0;
            };
            setDeliveryChargeValue(float2D(VendorDeliveryCharge || 0));
        }
        FetchData()
        //eslint-disable-next-line
    }, [CustomizedCakeDeatils, GOOGLE_API_KEY, DeliveryCharge]);

    // const handleFinalPricePerKg = (e) => {
    //     setPricePerKg(e.target.value);
    //     setViewFinalPrice(false);
    //     (e.target.value?.length === 0) ? setViewButtonValidate(true) : setViewButtonValidate(false);
    // };

    const handleDeliveryCharge = (e) => {
        setViewFinalPrice(false);
        (e.target.value?.length === 0) ? setViewButtonValidate(true) : setViewButtonValidate(false);
        (e.target.value) ? setDeliveryChargeValue(e.target.value) : setDeliveryChargeValue('');
    };

    const handleCloseHelpdeskVEditPricePopup = (e) => {
        setViewFinalPrice(false);
        setViewButtonValidate(false);
        // setPricePerKg();
        setDeliveryChargeValue(DeliveryCharge || 0);
        handleCloseEditPricePopup(e);
    };

    const handleCalculateFinalTotal = (e) => {
        e.preventDefault();
        if (DeliveryChargeValue?.toString()) {
            setViewFinalPrice(true);
            setViewButtonValidate(false);
            //Calculate Delievry Charge
            // if (CustomizedCakeDeatils?.DeliveryInformation === 'Delivery' && CustomizedCakeDeatils?.DeliveryAddress) {
            // await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${CustomizedCakeDeatils?.DeliveryAddress}&key=${GOOGLE_API_KEY}`)
            //         .then((res) => res.json())
            //         .then((data) => {
            //             setCoordinates(coordinates = {
            //                 Latitude: data.results[0].geometry.location.lat,
            //                 Longitude: data.results[0].geometry.location.lng
            //             });
            //         }).catch(err => console.log(err));
            //     const Distance = calculateDistance(CustomizedCakeDeatils?.GoogleLocation?.Latitude, CustomizedCakeDeatils?.GoogleLocation?.Longitude,
            //         coordinates?.Latitude, coordinates?.Longitude);
            //     if (DeliveryCharge?.message === 'No Records Found') {
            //         setDeliveryChargeValue(DeliveryChargeValue = 0);
            //     } else {
            //         if (Distance > 0) {
            //             let DistanceDividedByKm = parseFloat(Distance) / parseInt(DeliveryCharge[0]?.Km);
            //             setDeliveryChargeValue(DeliveryChargeValue = Math.round(parseFloat(DistanceDividedByKm) * parseInt(DeliveryCharge[0]?.Amount)));
            //         } else {
            //             setDeliveryChargeValue(DeliveryChargeValue = 0);
            //         };
            //     };
            // } else {
            //     setDeliveryChargeValue(DeliveryChargeValue = 0);
            // };
            let formData = {
                Type: "Kg",
                Price: CustomizedCakeDeatils?.Vendor_Price,
                ItemCount: "1",
                Flavour: CustomizedCakeDeatils?.Flavour,
                Shape: { Name: CustomizedCakeDeatils?.Shape, Price: "0" },
                Weight: CustomizedCakeDeatils?.Weight,
                Discount: CustomizedCakeDeatils?.Discount,
                DeliveryCharge: float2D(DeliveryChargeValue),
                Tax: Tax[0]?.Total_GST || "0",
                CalculationFor: "Ticket",
                SharePercentage:productShareperList[0]?.Percentage
            };
            dispatch({ type: CommonActions.GET_INVOICE_CALCULATION, payload: { type: "CCO", data: formData } })
            // let CakePrice, CakeTax, CakeFinalPrice;
            // CakePrice = parseInt(pricePerKg) * parseFloat(CustomizedCakeDeatils?.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]);
            // if (CustomizedCakeDeatils?.ExtraCharges) {
            //     CakePrice += parseFloat(CustomizedCakeDeatils?.ExtraCharges);
            // };
            // if (Tax?.length === 0) {
            //     setTaxValue(taxValue = 0);
            // } else {
            //     if (parseFloat(Tax[0]?.Total_GST) > 0) {
            //         CakeTax = ((parseFloat(CakePrice) + parseFloat(DeliveryChargeValue)) * parseFloat(Tax[0]?.Total_GST)) / 100;
            //     } else {
            //         CakeTax = 0;
            //     };
            //     setTaxValue(taxValue = CakeTax);
            // };
            // CakeFinalPrice = parseFloat(CakePrice) + parseFloat(taxValue) + parseFloat(DeliveryChargeValue) - parseFloat(CustomizedCakeDeatils?.Discount);
            // setFinalTotal(finalTotal = CakeFinalPrice);
        } else {
            setViewButtonValidate(true);
        };
    };

    return (
        <div>
            <Modal show={showHelpdeskVEditPricePopup} onHide={handleCloseHelpdeskVEditPricePopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleCloseHelpdeskVEditPricePopup}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-4">Edit Cake Cost</h4>
                    <div className="form-row">
                        <div className="col-md-6 col-sm-12">
                            <label className='float-left font-weight-bold'>Vendor&apos;s Price per Kg</label>
                            <div className='input-group'>
                                <input
                                    className='form-control'
                                    defaultValue={CustomizedCakeDeatils?.Vendor_Price}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <label className='float-left font-weight-bold'>Cake Weight</label>
                            <div className='input-group'>
                                <input
                                    className='form-control'
                                    defaultValue={CustomizedCakeDeatils?.Weight}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <label className='float-left font-weight-bold'>Delivery Information</label>
                            <div className='input-group'>
                                <input
                                    className='form-control'
                                    defaultValue={CustomizedCakeDeatils?.DeliveryInformation}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <label className='float-left font-weight-bold'>Delivery Charge<span className='text-danger'>*</span>{CustomizedCakeDeatils?.DeliveryInformation === 'Delivery' ? `(${float2D(distance)} Km)` : ''}</label>
                            <div className='input-group'>
                                <input
                                    type='number'
                                    onWheel={() => document.activeElement.blur()}
                                    className={viewButtonValidate ? 'form-control required-field' : 'form-control'}
                                    min={0} maxLength={6}
                                    onInput={NumberValidation}
                                    value={DeliveryChargeValue}
                                    onChange={handleDeliveryCharge}
                                />
                            </div>
                        </div>
                        {/* <div className="col-md-12 col-sm-12">
                            <label className='float-left font-weight-bold'>Final Price per Kg<span className='text-danger'>*</span></label>
                            <div className='input-group'>
                                <input
                                    type='number'
                                    className={viewButtonValidate ? 'form-control required-field' : 'form-control'}
                                    min={0} maxLength={6}
                                    onInput={(e) => {
                                        e.target.value = e.target.value?.replace(/[^0-9]/, '')
                                        if (e.target.value.length > e.target.maxLength) {
                                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                                        } else if (e.target.value < e.target.min) {
                                            e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                        }
                                    }}
                                    onChange={handleFinalPricePerKg}
                                />
                            </div>
                        </div> */}
                        {viewFinalPrice &&
                            <div className="col-md-12 col-sm-12">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="bold text-center">Final Price</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Price per Kg</th>
                                                <td>{float2D(CustomizedCakeDeatils?.Vendor_Price)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Weight</th>
                                                <td>{CustomizedCakeDeatils?.Weight}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td>{float2D(Invoice_Calculation?.DiscountPrice)}</td>
                                            </tr>
                                            {/* <tr>
                                                <th scope="row">ExtraCharges</th>
                                                <td>{float2D(Invoice_Calculation?.ExtraCharges)}</td>
                                            </tr> */}
                                            <tr>
                                                <th scope="row">Delivery Charge</th>
                                                <td>{float2D(Invoice_Calculation?.DeliveryCharge)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">CGST</th>
                                                {/* {Tax?.length > 0 && parseFloat(Tax[0]?.Total_GST) > 0 ? */}
                                                <td><span disabled className='mr-2 text-muted'><small>{parseFloat(Invoice_Calculation?.TaxPercentage) / 2}%</small></span> {float2D(Invoice_Calculation?.Gst)}</td>
                                                {/* :<td>0</td>
                                                } */}
                                            </tr>
                                            <tr>
                                                <th scope="row">SGST</th>
                                                {/* {Tax?.length > 0 && parseFloat(Tax[0]?.Total_GST) > 0 ? */}
                                                <td><span disabled className='mr-2 text-muted'><small>{parseFloat(Invoice_Calculation?.TaxPercentage) / 2}%</small></span> {float2D(Invoice_Calculation?.Sgst)}</td>
                                                {/* : <td>0</td>
                                                } */}
                                            </tr>
                                            <tr>
                                                <th scope="row">Total</th>
                                                <td><b>{float2D(Invoice_Calculation?.Total)}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {(!viewFinalPrice) &&
                            <div className="col-md-12 col-sm-12">
                                <p className='text-success cursor' onClick={handleCalculateFinalTotal}><u>Click here to View Total Cost</u></p>
                            </div>
                        }
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                disabled={viewFinalPrice ? false : true}
                                className="btn btn-primary shadow-none mr-3"
                                onClick={(e) => handleCustomizedCakeEditedPrice(e, CustomizedCakeDeatils?.Vendor_Price, (parseFloat(Invoice_Calculation?.Gst) + parseFloat(Invoice_Calculation?.Sgst)), Tax, Invoice_Calculation?.Total, Invoice_Calculation?.DeliveryCharge)}
                            // onClick={(e) => handleCustomizedCakeEditedPrice(e, pricePerKg, taxValue, Tax, finalTotal, DeliveryChargeValue)}
                            >
                                Intimate to Manager
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleCloseHelpdeskVEditPricePopup}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};


// function calculateDistance(lat1, lon1, lat2, lon2) {
//     var p = 0.017453292519943295;
//     // var c = Math.cos;
//     var a = 0.5 -
//         Math.cos((lat2 - lat1) * p) / 2 +
//         Math.cos(lat1 * p) * Math.cos(lat2 * p) * (1 - Math.cos((lon2 - lon1) * p)) / 2;
//     return 12742 * Math.asin(Math.sqrt(a));
// }
