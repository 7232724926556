import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IoMdHome } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import PageRefresh from '../../common/PageRefresh';

function Breadcrumb(props) {

    const { Page, handlePageRefresh } = props;

    const [changeIcon, setChangeIcon] = useState(false);

    const handleChangeIcon = (e) => {
        e.preventDefault();
        setChangeIcon(true);
        setTimeout(() => { setChangeIcon(false) }, 1000);
    };

    return (
        <Row>
            <Col md={10} sm={12}>
                <nav aria-label="breadcrumb">
                    {Page === 'HelpdeskV' &&
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item"><Link to="/helpdeskVdashboard"><IoMdHome className='home' /> Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Tickets</li>
                        </ol>}
                    {Page === 'HelpdeskC' &&
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item"><Link to="/Helpdesk-C-Dashboard"><IoMdHome className='home' /> Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Tickets</li>
                        </ol>}
                    {Page === 'Manager' &&
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item"><Link to="/Manager-Dashboard"><IoMdHome className='home' /> Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Tickets</li>
                        </ol>}
                    {Page === 'Management' &&
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item"><Link to="/Management-Dashboard"><IoMdHome className='home' /> Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Tickets</li>
                        </ol>}

                </nav>
            </Col>
            <Col md={2} sm={12} className='d-flex justify-content-end mt-2'>
                <div onClick={handleChangeIcon}>
                    {changeIcon ?
                        <div>
                            <button className='refresh-styles'><RiRefreshLine className="h5 mt-1" /></button>
                        </div> :
                        <PageRefresh handlePageRefresh={handlePageRefresh} />
                    }
                </div>
            </Col>
        </Row>

        //old code
        // <Row>
        //     <Col md={10} sm={12}>
        //         <nav aria-label="breadcrumb">
        //             {Page === 'HelpdeskC' ?
        //                 <ol className="breadcrumb pl-0">
        //                     <li className="breadcrumb-item"><Link to="/helpdeskVdashboard"><IoMdHome className='home' /> Home</Link></li>
        //                     <li className="breadcrumb-item active" aria-current="page">Tickets</li>
        //                 </ol> :
        //                 Page === 'Manager' ?
        //                     <ol className="breadcrumb pl-0">
        //                         <li className="breadcrumb-item"><Link to="/Manager-Dashboard"><IoMdHome className='home' /> Home</Link></li>
        //                         <li className="breadcrumb-item active" aria-current="page">Tickets</li>
        //                     </ol> :
        //                     <ol className="breadcrumb pl-0">
        //                         <li className="breadcrumb-item"><Link to="/Management-Dashboard"><IoMdHome className='home' /> Home</Link></li>
        //                         <li className="breadcrumb-item active" aria-current="page">Tickets</li>
        //                     </ol>
        //             }
        //         </nav>
        //     </Col>
        //     <Col md={2} sm={12} className='d-flex justify-content-end mt-2'>
        //         <div onClick={handleChangeIcon}>
        //             {changeIcon ?
        //                 <div>
        //                     <button className='refresh-styles'><RiRefreshLine className="h5 mt-1" /></button>
        //                 </div> :
        //                 <PageRefresh handlePageRefresh={handlePageRefresh} />
        //             }
        //         </div>
        //     </Col>
        // </Row>
    );
};

export default Breadcrumb;