import React from 'react';
import { Row, Col, Form, Modal } from 'react-bootstrap';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
// import { PushNotification } from '../../../common/PushNotification';
import commonActions from '../../../../redux/Common/actions'
import { useDispatch } from 'react-redux';

export default function TicketIntimationPopup(props) {
    const dispatch = useDispatch();
    const { showIntimateListPopup, handleCloseIntimatePopup, auth, handleChangedAccess, loader,
        enableIntimate, handleUpdateIntimate, TicketDetails, VendorDetails, handleIntimateToVendorForCCOrder } = props;

    const handleUpdate = (e) => {
        if (auth?.token?.result?.TypeOfUser === 'Manager' &&
            TicketDetails?.Vendor_Approved_Status === "Approved" && VendorDetails?.Notification_Id) {
            let pushMessageData = {
                type: 'vendor',
                registration_ids: [VendorDetails?.Notification_Id],
                title: 'Cakey',
                body: "New Customised Cake Order Received",
            }
            //New Push notification  
            dispatch({ type: commonActions.SEND_PUSH_NOTIFICATION, payload: pushMessageData })

            //old push notification
            // PushNotification(pushMessageData);
            handleIntimateToVendorForCCOrder(e)
            // handleUpdateIntimate(e);
        } else {
            handleUpdateIntimate(e);
        };
    };

    return (
        <div>
            <Modal show={showIntimateListPopup} onHide={handleCloseIntimatePopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleCloseIntimatePopup}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Ticket Intimate to</h4>
                    <div>
                        <Row className="d-flex justify-content-center flex-column">
                            <Col md={12}>
                                {TicketDetails?.CauseOfTicket === 'Miscellaneous' ?
                                    <div className='status-radio-button'>
                                        <Form.Check
                                            className='mb-2'
                                            type="checkbox"
                                            label="Vendor Helpdesk"
                                            onChange={(e) => handleChangedAccess(e, 'HelpdeskV')}
                                        />
                                        <Form.Check
                                            className='mb-2'
                                            type="checkbox"
                                            label="Manager"
                                            checked
                                        // onChange={(e) => handleChangedAccess(e, 'Manager')}
                                        />
                                        <Form.Check
                                            className='mb-2'
                                            type="checkbox"
                                            label="Management"
                                            onChange={(e) => handleChangedAccess(e, 'Management')}
                                        />
                                        <Form.Check
                                            className='mb-2'
                                            type="checkbox"
                                            label="Accounts"
                                            onChange={(e) => handleChangedAccess(e, 'Accounts')}
                                        />
                                    </div> :
                                    <div className='status-radio-button'>
                                        {auth?.token?.result?.TypeOfUser === 'Helpdesk V' &&
                                            <Form.Check
                                                className='mb-2'
                                                type="checkbox"
                                                label="Customer Helpdesk"
                                                onChange={(e) => handleChangedAccess(e, 'HelpdeskC')}
                                            />
                                        }
                                        {auth?.token?.result?.TypeOfUser === 'Helpdesk C' &&
                                            <Form.Check
                                                className='mb-2'
                                                type="checkbox"
                                                label="Vendor Helpdesk"
                                                onChange={(e) => handleChangedAccess(e, 'HelpdeskV')}
                                            />
                                        }
                                        {(auth?.token?.result?.TypeOfUser === 'Helpdesk C' ||
                                            auth?.token?.result?.TypeOfUser === 'Helpdesk V') &&
                                            <>
                                                {TicketDetails?.CauseOfTicket !== 'Vendor Request' &&
                                                    <Form.Check
                                                        className='mb-2'
                                                        type="checkbox"
                                                        label="Manager"
                                                        onChange={(e) => handleChangedAccess(e, 'Manager')}
                                                    />
                                                }
                                                <Form.Check
                                                    className='mb-2'
                                                    type="checkbox"
                                                    label="Management"
                                                    onChange={(e) => handleChangedAccess(e, 'Management')}
                                                />
                                                <Form.Check
                                                    className='mb-2'
                                                    type="checkbox"
                                                    label="Accounts"
                                                    onChange={(e) => handleChangedAccess(e, 'Accounts')}
                                                />
                                            </>
                                        }
                                        {(auth?.token?.result?.TypeOfUser === 'Accounts' ||
                                            auth?.token?.result?.TypeOfUser === 'Manager') &&
                                            <>
                                                <Form.Check
                                                    className='mb-2'
                                                    type="checkbox"
                                                    label="Customer Helpdesk"
                                                    onChange={(e) => handleChangedAccess(e, 'HelpdeskC')}
                                                />
                                                <Form.Check
                                                    className='mb-2'
                                                    type="checkbox"
                                                    label="Vendor Helpdesk"
                                                    onChange={(e) => handleChangedAccess(e, 'HelpdeskV')}
                                                />
                                            </>
                                        }
                                    </div>
                                }
                            </Col>
                        </Row>
                        {loader ?
                            <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                {(auth?.token?.result?.TypeOfUser === 'Manager' &&
                                    TicketDetails?.Vendor_Approved_Status === "Approved") &&
                                    <div className='text-left text-success mt-2'>This order will be intimate to Vendor</div>
                                }
                                <button type="submit" disabled={enableIntimate} className="btn btn-primary shadow-none mr-3" onClick={handleUpdate}>Intimate</button>
                                <button type="submit" className="btn btn-secondary shadow-none" onClick={handleCloseIntimatePopup}>Cancel</button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}