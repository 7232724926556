import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../redux/Tickets/actions';
import VendorActions from '../../../../redux/Vendor/actions';
import userActions from '../../../../redux/users/actions';
import AccountsActions from '../../../../redux/Accounts/actions';
import commonActions from '../../../../redux/Common/actions'
import { useHistory, useLocation } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import Preloader from '../../../layouts/Preloader';
import OrderDetailsPopup from '../../../CommonFolders/Ticket-Details/Popups/OrderDetailsPopup';
import VendorDetailsPopup from '../../../CommonFolders/Ticket-Details/Popups/VendorDetailsPopup';
import RefundPopup from './Popups/RefundPopup';
import TicketIntimationPopup from '../../../CommonFolders/Ticket-Details/Popups/TicketIntimationPopup';
import SendPaymentLinkPopup from './Popups/SendPaymentLinkPopup';
import FlavourList from '../../../sections/ArrayMapping/FlavourList';
import { float2D } from '../../../../utils/Validation';
// import { PushNotification } from '../../../common/PushNotification';
import RefundFor15MinsCancelPopup from './Popups/RefundFor15MinsCancelPopup';
import EnterSolutionPopup from '../../../CommonFolders/Ticket-Details/Popups/EnterSolutionPopup';
import UserDetailsPopup from '../../../CommonFolders/Ticket-Details/Popups/UserDetailsPopup';
import RefundForMiscellaneousPopup from './Popups/RefundForMiscellaneous';
// import { useForm } from "react-hook-form";

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory()
    const state = useLocation();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [showOrderDetailsPopup, setShowOrderDetailsPopup] = useState(false);
    const [showUserDetailsPopup, setShowUserDetailsPopup] = useState(false);
    const [showRefundPopup, setShowRefundPopup] = useState(false);
    const [showIntimateListPopup, setShowIntimateListPopup] = useState(false);
    const [showPaymentListPopup, setShowPaymentLinkPopup] = useState(false);
    const [showVendorDetailsPopup, setShowVendorDetailsPopup] = useState(false);
    const [enableIntimate, setEnableIntimate] = useState(true);
    const [showRefundFor15MinsCancelPopup, setShowRefundFor15MinsCancelPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [showEnterSolutionPopup, setShowEnterSolutionPopup] = useState(false);
    const [showRefundForMiscellaneousPopup, setShowRefundForMiscellaneousPopup] = useState(false);

    let [last_Intimate, setLast_Intimate] = useState([]);

    const { TicketDetails, Ticket_Response, OrderDetailsByTypeOfOrder } = useSelector(state => state.TicketReducer);

    const { VendorDetails } = useSelector(state => state.VendorReducer);

    const { CouponCodeList } = useSelector(state => state.AccountsReducer);

    const { UserDetails } = useSelector(state => state.registeredUserReducer);

    const auth = useSelector((state) => state.authReducer);

    // const { register, handleSubmit, formState: { errors }, setError, clearErrors, setValue, resetField } = useForm();

    const Id = state.state.Id;
    const OrderID = TicketDetails?.OrderID;
    const TypeOfUser = TicketDetails?.TypeOfOrder;
    const UserID = TicketDetails?.UserID;
    const LoggedIn_User = {
        ID: auth?.token?.result?._id, Name: auth?.token?.result?.Name,
        Email: auth?.token?.result?.Email, TypeOfUser: auth?.token?.result?.TypeOfUser
    }

    useEffect(() => {
        dispatch({ type: actions.GET_TICKET_DETAILS, payload: Id });
        dispatch({ type: AccountsActions.GET_COUPONCODE_LIST });
        //eslint-disable-next-line
    }, [Id]);

    useEffect(() => {
        if (OrderID) {
            dispatch({
                type: actions.GET_ORDER_DETAILS_BY_TYPEOFORDER,
                payload: { Id: OrderID, TypeOfOrder: TypeOfUser?.toLowerCase()?.replace(/ /g, '') }
            });
        }
        //eslint-disable-next-line
    }, [OrderID, TypeOfUser]);

    useEffect(() => {
        if (UserID) {
            dispatch({ type: userActions.GET_USER_DETAILS, payload: UserID })
        }
        //eslint-disable-next-line
    }, [UserID]);

    const handleCloseDetails = (e) => {
        e.preventDefault();
        (state?.state?.Page === 'list') ? history.push('/Accounts-Tickets-List') :
            history.push('/Accounts-Dashboard');
    };

    //set vendor details popup -> true 
    const handleShowVendorDetailsPopup = (e) => {
        e.preventDefault();
        dispatch({
            type: VendorActions.GET_VENDOR_DETAILS_BY_ID,
            payload: { Id: TicketDetails?.VendorID }
        });
        setShowVendorDetailsPopup(true);
    };

    const handleShowOrderDetailsPopup = (e) => {
        e.preventDefault();
        // dispatch({
        //     type: actions.GET_ORDER_DETAILS_BY_TYPEOFORDER,
        //     payload: { Id: TicketDetails?.OrderID, TypeOfOrder: TicketDetails?.TypeOfOrder?.toLowerCase()?.replace(/ /g, '') }
        // });
        setShowOrderDetailsPopup(true);
    };

    const handleCloseDetailsPopup = () => {
        setShowOrderDetailsPopup(false);
        // dispatch({ type: actions.SET_ORDER_DETAILS_BY_TYPEOFORDER, payload: [] });
        setShowVendorDetailsPopup(false);
        setShowUserDetailsPopup(false);
        // dispatch({ type: VendorActions.SET_VENDOR_DETAILS_BY_ID, payload: [] });
    };

    const handleAcceptTicket = () => {
        const data = {
            Accounts_Accepted_By: LoggedIn_User,
        }
        dispatch({ type: actions.ACCEPT_TICKET, payload: { Id: Id, data: data } });
    };

    const handleRefundPopup = (e) => {
        e.preventDefault();
        // dispatch({
        //     type: actions.GET_ORDER_DETAILS_BY_TYPEOFORDER,
        //     payload: { Id: TicketDetails?.OrderID, TypeOfOrder: TicketDetails?.TypeOfOrder?.toLowerCase()?.replace(/ /g, '') }
        // });
        setShowRefundPopup(true);
    };

    const handleCloseRefundPopup = () => {
        setShowRefundPopup(false);
    };

    const handleRefundPayment = (e, data) => {
        e.preventDefault();
        if (TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor') {
            let Last_Intimate = ['HelpdeskV'];
            let formData = {
                Ticket_Approved_Status: TicketDetails?.Ticket_Approved_Status,
                Total_Amount: OrderDetailsByTypeOfOrder?.Total,
                Refund_Amount: data.Refund_Amount,
                Refund_Status: data.RefundType === 'Cash' ? 'Success' : 'Coupon Generated',
                Refund_By: LoggedIn_User,
                RefundType: data.RefundType,
                CouponCode: data.CouponCode,
                CouponType: data.CouponType,
                CouponValue: data.CouponValue,
                UserID: TicketDetails?.UserID,
                User_ID: TicketDetails?.User_ID,
                OrderID: TicketDetails?.OrderID,
                Order_ID: TicketDetails?.Order_ID,
                Last_Intimate: Last_Intimate,
                For_Display: data.For_Display
            };
            setLoader(true);
            dispatch({ type: actions.REFUND_PAYMENT, payload: { Id: TicketDetails?._id, data: formData } });
        } else if (TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer') {
            let Last_Intimate = ['HelpdeskC'];
            let formData = {
                Total_Amount: data.Total_Amount,
                Refund_Amount: data.Refund_Amount,
                Refund_Status: data.RefundType === 'Cash' ? 'Success' : 'Coupon Generated',
                Refund_By: LoggedIn_User,
                RefundType: data.RefundType,
                CouponCode: data.CouponCode,
                CouponType: data.CouponType,
                CouponValue: data.CouponValue,
                UserID: TicketDetails?.UserID,
                OrderID: TicketDetails?.OrderID,
                Order_ID: TicketDetails?.Order_ID,
                // TypeOfOrder: TicketDetails?.TypeOfOrder,
                Last_Intimate: Last_Intimate,
                For_Display: data.For_Display
            };
            setLoader(true);
            dispatch({ type: actions.REFUND_PAYMENT_FOR_CUSTOMER_CANCEL, payload: { Id: TicketDetails?._id, data: formData } });
        };
    };

    const handleIntimatePopup = (e) => {
        e.preventDefault();
        setShowIntimateListPopup(true);
    };

    const handleCloseIntimatePopup = () => {
        setShowIntimateListPopup(false);
        setLast_Intimate([]);
        setEnableIntimate(true)
    };

    const handleChangedAccess = (e, selected) => {
        let fieldName = selected;
        if (e.target.checked) {
            setLast_Intimate(last_Intimate = [...last_Intimate, fieldName]);
        } else {
            if (last_Intimate.includes(fieldName)) {
                let Array = [...last_Intimate];
                Array.splice(last_Intimate.indexOf(fieldName), 1);
                setLast_Intimate(last_Intimate = Array);
            }
        };
        last_Intimate.length === 0 ? setEnableIntimate(true) : setEnableIntimate(false);
    };

    const handleUpdateIntimate = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch({
            type: actions.INTIMATE_TICKET, payload: {
                Id: TicketDetails?._id,
                data: {
                    Updated_By: LoggedIn_User,
                    Last_Intimate: last_Intimate,
                }
            }
        });
    };

    const handlePaymentListPopup = (e) => {
        e.preventDefault();
        setShowPaymentLinkPopup(true);
    };

    const handleClosePaymentLinkPopup = (e) => {
        setShowPaymentLinkPopup(false);
    };

    const handleSendPaymentLink = (e) => {
        e.preventDefault();
        if (TicketDetails?.CauseOfTicket === 'Change Requests' && UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
            let pushMessageData = {
                type: 'customer',
                registration_ids: [UserDetails.Notification_Id],
                title: 'Cakey',
                body: `Your Flavour/Shape Change Request Difference Amount is Received on Order(${TicketDetails?.Order_ID})`,
                // body: 'New updation received for your order',
            }

             //New Push notification  
             dispatch({ type: commonActions.SEND_PUSH_NOTIFICATION, payload: pushMessageData })

             //old push notification
            // PushNotification(pushMessageData);
        }
        let formData;
        formData = {
            Difference_In_Price: TicketDetails?.Total_Difference_In_Price,
            Payment_Intimated_By: LoggedIn_User,
            OrderID: TicketDetails?.OrderID,
            Ticket_ID: TicketDetails?.Id,
            PaymentType: OrderDetailsByTypeOfOrder?.PaymentType
        };
        setLoader(true);
        dispatch({
            type: actions.SEND_PAYMENT_LINK_FOR_CHANGE_REQUEST,
            payload: { Id: TicketDetails?._id, data: formData }
        });
    };

    const handleRefundFor15MinsCancelPopup = (e) => {
        e.preventDefault();
        setShowRefundFor15MinsCancelPopup(true);
    };

    const handleCloseRefundFor15MinsCancelPopup = (e) => {
        setShowRefundFor15MinsCancelPopup(false);
        setShowRefundForMiscellaneousPopup(false);
    };

    const handleRefundPaymentFor15MinsCancel = (data) => {
        let formData = {
            Refund_Amount: data.Refund_Amount,
            Refund_Status: 'Success',
            Refund_By: LoggedIn_User,
            Refund_Type: data.RefundType,
            OrderID: TicketDetails?.OrderID,
            Order_ID: TicketDetails?.Order_ID,
            UserID: TicketDetails?.UserID,
            TypeOfOrder: TicketDetails?.TypeOfOrder,
            Last_Intimate: ['HelpdeskC']
        };
        // if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
        //     let pushMessageData = {
        //         type: 'customer',
        //         registration_ids: [UserDetails.Notification_Id],
        //         title: 'Cakey',
        //         body: `Your cancelled order's(${TicketDetails?.Order_ID}) payment is refunded successfully`,
        //     }
        //     PushNotification(pushMessageData);
        // };
        setLoader(true);
        dispatch({ type: actions.REFUND_FOR_CANCEL_WITHIN_15MINS, payload: { Id: TicketDetails?._id, data: formData } });
    };

    const handleEnterSolutionPopup = (e) => {
        e.preventDefault();
        setShowEnterSolutionPopup(true);
    };

    const handleCloseEnterSolutionPopup = (e) => {
        setShowEnterSolutionPopup(false);
    };

    const handleUpdateSolution = (solution) => {
        let Ticket_Intimation = [];
        if (TicketDetails?.CauseOfTicket === 'Vendor Request') {
            Ticket_Intimation = ['HelpdeskV', 'Manager'];
        }
        let TOU;
        (LoggedIn_User?.TypeOfUser === 'Helpdesk C') ? TOU = 'Customer Helpdesk' : (LoggedIn_User?.TypeOfUser === 'Helpdesk V') ? TOU = 'Vendor Helpdesk' : TOU = LoggedIn_User?.TypeOfUser
        let formData = {
            Solution: solution,
            Solution_Entered_By: LoggedIn_User,
            Ticket_Intimation: Ticket_Intimation,
            // For_Display: `New Solution given by ${LoggedIn_User?.TypeOfUser}`
            For_Display: `New Solution given by ${TOU}`
        };
        setLoader(true);
        dispatch({
            type: actions.ENTER_SOLUTION_FOR_TICKETS,
            payload: { Id: TicketDetails?._id, data: formData }
        });
    };

    const handleShowUserDetailsPopup = (e) => {
        e.preventDefault();
        setShowUserDetailsPopup(true);
    };

    useEffect(() => {
        if (Ticket_Response?.statusCode === 200) {
            setLoader(false);
            setShowRefundPopup(false);
            setShowIntimateListPopup(false);
            setLast_Intimate([]);
            setShowPaymentLinkPopup(false);
            setShowRefundFor15MinsCancelPopup(false);
            setShowEnterSolutionPopup(false);
            setShowRefundForMiscellaneousPopup(false);
        } else {
            setLoader(false);
        };
    }, [Ticket_Response]);

    useEffect(() => {
        (TicketDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [TicketDetails]);

    const handleRefundForMiscellaneousPopup = (e) => {
        e.preventDefault();
        setShowRefundForMiscellaneousPopup(true);
    };

    const handleRefundPaymentForMiscellaneous = (data) => {
        setLoader(true);
        let payloadData = {
            ...data,
            Refund_By: LoggedIn_User
        }
        dispatch({ type: actions.MISCELLANEOUS_REFUND_BY_ACCOUNTS, payload: { Id: TicketDetails?._id, data: payloadData } });
    };

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb Page={state?.state?.Page} />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel tabledata-background">
                                <div className="ms-panel-header">
                                    <h6>Ticket Details</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">TICKET INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Ticket ID</th>
                                                <td><b>{TicketDetails?.Id}</b></td>
                                            </tr>
                                            {TicketDetails?.Ticket_Status &&
                                                <tr>
                                                    <th scope="row">Ticket Status</th>
                                                    <td><span className={(TicketDetails?.Ticket_Status?.toUpperCase())?.replace(/ /g, '')}>{TicketDetails?.Ticket_Status}</span></td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Cause of Ticket</th>
                                                <td>{TicketDetails?.CauseOfTicket}</td>
                                            </tr>
                                            {TicketDetails?.IsVendorAvailable &&
                                                <tr>
                                                    <th scope="row">Vendor Availability</th>
                                                    <td><span className='text-danger'><b>{TicketDetails?.IsVendorAvailable === 'No Vendors' ? 'Vendors are not Available' : ''}</b></span></td>
                                                </tr>
                                            }

                                            {TicketDetails?.TypeOfMiscellaneous &&
                                                <tr>
                                                    <th scope="row">Type of Miscellaneous</th>
                                                    <td><b>{TicketDetails?.TypeOfMiscellaneous}</b></td>
                                                </tr>
                                            }
                                            {TicketDetails?.TypeOfOrder &&
                                                <tr>
                                                    <th scope="row">Type of Order</th>
                                                    <td>{TicketDetails?.TypeOfOrder === 'Customized Cake Order' ? 'Customised Cake Order' : TicketDetails?.TypeOfOrder}</td>
                                                </tr>
                                            }
                                            {OrderDetailsByTypeOfOrder?.CakeSubType &&
                                                <tr>
                                                    <th scope="row">Product SubType</th>
                                                    <td><b>{OrderDetailsByTypeOfOrder?.CakeSubType}</b></td>
                                                </tr>
                                            }
                                            {TicketDetails?.Order_ID &&
                                                <tr>
                                                    <th scope="row">Order ID</th>
                                                    <td className='cursor' onClick={handleShowOrderDetailsPopup}>
                                                        <span className='text-primary mr-2 '> Click to View</span>
                                                        <button className='btn btn-sm btn-success mt-0'>{TicketDetails?.Order_ID}</button>

                                                    </td>
                                                    {/* <td>{TicketDetails?.Order_ID}</td> */}
                                                </tr>
                                            }
                                            {/* {TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' &&
                                                <tr>
                                                    <th scope="row">Payment Status</th>
                                                    <td><b>{OrderDetailsByTypeOfOrder?.PaymentStatus}</b></td>
                                                </tr>
                                            } */}
                                            {TicketDetails?.Order_Status &&
                                                <tr>
                                                    <th scope="row">Order Status</th>
                                                    <td className={TicketDetails?.Order_Status === 'Sent' && 'font-weight-bold'}>{TicketDetails?.Order_Status}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.CauseOfTicket === 'Change Requests' &&
                                                <>
                                                    {TicketDetails?.Flavour?.length > 0 &&
                                                        <tr>
                                                            <th scope="row">Requested Flavour</th>
                                                            <td><FlavourList FlavoursList={TicketDetails?.Flavour} /></td>
                                                        </tr>
                                                    }
                                                    {TicketDetails?.Shape &&
                                                        <tr>
                                                            <th scope="row">Requested Shape</th>
                                                            <td>{`${TicketDetails?.Shape?.Name} - Additional Rs.${TicketDetails?.Shape?.Price}/Kg`}</td>
                                                        </tr>
                                                    }
                                                </>
                                            }
                                            {TicketDetails?.CauseOfTicket === 'Cakey Direct-General Order' &&
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{TicketDetails?.Weight}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.CauseOfTicket === 'Customized Cake through Vendor' &&
                                                <>
                                                    <tr>
                                                        <th scope="row">Price</th>
                                                        <td>{float2D(TicketDetails?.Price)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Total</th>
                                                        <td>{float2D(TicketDetails?.Total)}</td>
                                                    </tr>
                                                </>
                                            }
                                            {TicketDetails?.Product_ID &&
                                                <tr>
                                                    <th scope="row">Product ID</th>
                                                    <td>{TicketDetails?.Product_ID}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.Vendor_ID &&
                                                <tr>
                                                    <th scope="row">Vendor ID</th>
                                                    <td className='cursor' onClick={handleShowVendorDetailsPopup}>
                                                        <span className='text-primary mr-2 '> Click to View</span>
                                                        <button className='btn btn-sm btn-success mt-0'>{TicketDetails?.Vendor_ID}</button>

                                                    </td>
                                                    {/* <td>{TicketDetails?.Vendor_ID}</td> */}
                                                </tr>
                                            }
                                            {TicketDetails?.User_ID &&
                                                <tr>
                                                    <th scope="row">Customer ID</th>
                                                    <td className='cursor' onClick={handleShowUserDetailsPopup}>
                                                        <span className='text-primary mr-2 '> Click to View</span>
                                                        <button className='btn btn-sm btn-success mt-0'>{TicketDetails?.User_ID}</button>

                                                    </td>
                                                    {/* <td>{TicketDetails?.Vendor_ID}</td> */}
                                                </tr>
                                            }
                                            {(TicketDetails?.CauseOfTicket === 'Vendor Request' || TicketDetails?.CauseOfTicket === 'Customized Cake through Vendor') &&
                                                <tr>
                                                    <th scope="row">Vendor Name</th>
                                                    <td>{TicketDetails?.VendorName}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Ticket Raised On</th>
                                                <td>{TicketDetails?.Ticket_Raised_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Ticket Raised By</th>
                                                <td>{TicketDetails?.Ticket_Raised_By?.Name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {(TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor') &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">PRICE INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <>
                                                    {TicketDetails?.TypeOfOrder === 'Cake Order' &&
                                                        <>
                                                            <tr>
                                                                <th scope="row">Weight</th>
                                                                <td>{OrderDetailsByTypeOfOrder?.Weight}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Existing Price per Kg</th>
                                                                <td>{float2D(OrderDetailsByTypeOfOrder?.Price)}</td>
                                                            </tr>
                                                            {TicketDetails?.NewPrice &&
                                                                <tr>
                                                                    <th scope="row">New Price per Kg</th>
                                                                    <td>{float2D(TicketDetails?.NewPrice)}</td>
                                                                </tr>
                                                            }
                                                        </>

                                                    }
                                                    {(OrderDetailsByTypeOfOrder?.CakeSubType === "Brownie" ||
                                                        OrderDetailsByTypeOfOrder?.CakeSubType === "Cookies" ||
                                                        OrderDetailsByTypeOfOrder?.CakeSubType === "Chocolates" ||
                                                        OrderDetailsByTypeOfOrder?.CakeSubType === 'Tea Cake' ||
                                                        OrderDetailsByTypeOfOrder?.CakeSubType === 'Cheese Cake') &&
                                                        <>
                                                            <tr>
                                                                <th scope="row">Weight</th>
                                                                <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.Weight}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Existing Price per Kg</th>
                                                                <td>{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.PricePerKg)}</td>
                                                            </tr>
                                                            {TicketDetails?.NewPrice &&
                                                                <tr>
                                                                    <th scope="row">New Price per Kg</th>
                                                                    <td>{float2D(TicketDetails?.NewPrice)}</td>
                                                                </tr>
                                                            }
                                                        </>
                                                    }
                                                    {(OrderDetailsByTypeOfOrder?.CakeSubType === "Cake Dessert") &&
                                                        <>
                                                            <tr>
                                                                <th scope="row">Weight</th>
                                                                <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.Weight}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Product Count</th>
                                                                <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.ProductCount}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Existing Price per Unit</th>
                                                                <td>{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.PricePerUnit)}</td>
                                                            </tr>
                                                            {TicketDetails?.NewPrice &&
                                                                <tr>
                                                                    <th scope="row">New Price per Unit</th>
                                                                    <td>{float2D(TicketDetails?.NewPrice)}</td>
                                                                </tr>
                                                            }
                                                        </>
                                                    }
                                                    {(OrderDetailsByTypeOfOrder?.CakeSubType === "macaroons" ||
                                                        OrderDetailsByTypeOfOrder?.CakeSubType === "Cup Cake") &&
                                                        <>
                                                            <tr>
                                                                <th scope="row">Piece</th>
                                                                <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.Piece}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Product Count</th>
                                                                <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.ProductCount}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Existing Price per Box</th>
                                                                <td>{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.PricePerBox)}</td>
                                                            </tr>
                                                            {TicketDetails?.NewPrice &&
                                                                <tr>
                                                                    <th scope="row">New Price per Box</th>
                                                                    <td>{float2D(TicketDetails?.NewPrice)}</td>
                                                                </tr>
                                                            }
                                                        </>

                                                    }

                                                </>
                                                {(TicketDetails?.TypeOfOrder === 'Cake Order' || TicketDetails?.TypeOfOrder === 'Product Order') &&
                                                    <>

                                                        {OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice &&
                                                            <tr>
                                                                <th scope="row">Existing Topper Charges</th>
                                                                <td>{float2D(OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice)}</td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <th scope="row">Existing Discount</th>
                                                            <td>{float2D(OrderDetailsByTypeOfOrder?.Discount)}</td>
                                                        </tr>

                                                        {OrderDetailsByTypeOfOrder?.DeliveryCharge &&
                                                            <tr>
                                                                <th scope="row">Existing Delivery Charges</th>
                                                                <td>{float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge)}</td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <th scope="row">Existing CGST</th>
                                                            <td>{float2D((OrderDetailsByTypeOfOrder?.Gst))}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Existing SGST</th>
                                                            <td>{float2D((OrderDetailsByTypeOfOrder?.Sgst))}</td>
                                                        </tr>

                                                        {OrderDetailsByTypeOfOrder?.CouponValue &&
                                                            <tr>
                                                                <th scope="row">Coupon Value</th>
                                                                <td><b>{float2D(OrderDetailsByTypeOfOrder?.CouponValue)}</b></td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <th scope="row">Existing Total</th>
                                                            <td><b>{float2D(OrderDetailsByTypeOfOrder?.Total)}</b></td>
                                                        </tr>
                                                    </>
                                                }

                                                {(TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor' && TicketDetails?.CostDifference &&
                                                    TicketDetails?.FinalCost && TicketDetails?.VendorCallConversation && TicketDetails?.NewExtraCharges &&
                                                    TicketDetails?.NewTopperCharges && TicketDetails?.NewTax && TicketDetails?.NewDeliveryCharges &&
                                                    TicketDetails?.NewDiscount
                                                ) &&
                                                    <>
                                                        <tr>
                                                            <th scope="row">New Total</th>
                                                            <td><b>{float2D(TicketDetails?.FinalCost)}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Difference in Total</th>
                                                            <td><b>{float2D((parseFloat(OrderDetailsByTypeOfOrder?.Total)) + (parseFloat(OrderDetailsByTypeOfOrder?.CouponValue || 0)) - parseFloat(TicketDetails?.FinalCost))}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Vendor Call Conversation</th>
                                                            <td>{TicketDetails?.VendorCallConversation}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">New Extra Charges</th>
                                                            <td>{float2D(TicketDetails?.NewExtraCharges)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">New Topper Charges</th>
                                                            <td>{float2D(TicketDetails?.NewTopperCharges)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">New Discount</th>
                                                            <td>{float2D(TicketDetails?.NewDiscount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">New Delivery Charges</th>
                                                            <td>{float2D(TicketDetails?.NewDeliveryCharges)}</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">New CGST</th>
                                                            <td>{float2D((TicketDetails?.NewTax) / 2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">New SGST</th>
                                                            <td>{float2D((TicketDetails?.NewTax) / 2)}</td>
                                                        </tr>
                                                        {(TicketDetails?.Refund_Amount && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                            <tr>
                                                                <th scope="row">Refund Amount</th>
                                                                <td><b>{float2D(TicketDetails?.Refund_Amount)}</b></td>
                                                            </tr>
                                                        }
                                                        {(TicketDetails?.RefundType && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                            <tr>
                                                                <th scope="row">Refund Type</th>
                                                                <td>{TicketDetails?.RefundType}</td>
                                                            </tr>
                                                        }
                                                        {(TicketDetails?.RefundType === 'Coupon' && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                            <>
                                                                <tr>
                                                                    <th scope="row">Coupon Type</th>
                                                                    <td>{TicketDetails?.CouponType}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Coupon Value</th>
                                                                    <td>{TicketDetails?.CouponType === 'Percentage' ? TicketDetails?.CouponValue + '%' : 'Rs. ' + float2D(TicketDetails?.CouponValue)}</td>
                                                                </tr>
                                                            </>

                                                        }
                                                        {(TicketDetails?.Refund_Status && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                            <tr>
                                                                <th scope="row">Refund Status</th>
                                                                <td className='font-weight-bold'>{TicketDetails?.Refund_Status}</td>
                                                            </tr>
                                                        }
                                                        {(TicketDetails?.Refund_On && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                            <tr>
                                                                <th scope="row">Refund On</th>
                                                                <td>{TicketDetails?.Refund_On}</td>
                                                            </tr>
                                                        }
                                                        {(TicketDetails?.Refund_By && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                            <tr>
                                                                <th scope="row">Refund By</th>
                                                                <td>{TicketDetails?.Refund_By?.Name} - {TicketDetails?.Refund_By?.Email}</td>
                                                            </tr>
                                                        }
                                                    </>

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {TicketDetails?.HelpdeskC_Accepted_By &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">CUSTOMER HELPDESK</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Ticket Accepted By</th>
                                                    <td>{TicketDetails?.HelpdeskC_Accepted_By?.Name} - {TicketDetails?.HelpdeskC_Accepted_By?.Email}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Ticket Accepted On</th>
                                                    <td>{TicketDetails?.HelpdeskC_Accepted_On}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {TicketDetails?.HelpdeskV_Accepted_By &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">VENDOR HELPDESK</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Ticket Accepted By</th>
                                                    <td>{TicketDetails?.HelpdeskV_Accepted_By?.Name} - {TicketDetails?.HelpdeskV_Accepted_By?.Email}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Ticket Accepted On</th>
                                                    <td>{TicketDetails?.HelpdeskV_Accepted_On}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {((TicketDetails?.CauseOfTicket === 'Cancellation Within 15mins' && TicketDetails?.Refund_Status) || TicketDetails?.CauseOfTicket !== 'Cancellation Within 15mins') &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">TICKET PROCESS</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                {TicketDetails?.HelpdeskV_Input &&
                                                    <tr>
                                                        <th scope="row">Vendor Helpdesk Input</th>
                                                        <td>{TicketDetails?.HelpdeskV_Input}</td>
                                                    </tr>
                                                }
                                                {TicketDetails?.Ticket_Approved_Status &&
                                                    <tr>
                                                        <th scope="row">Ticket Approved Status</th>
                                                        <td><span className={(TicketDetails?.Ticket_Approved_Status?.toUpperCase())?.replace(/ /g, '')}>{TicketDetails?.Ticket_Approved_Status}</span></td>
                                                    </tr>
                                                }
                                                {TicketDetails?.Ticket_Approved_Status_Updated_On &&
                                                    <tr>
                                                        <th scope="row">Ticket Approved Status Updated On</th>
                                                        <td>{TicketDetails?.Ticket_Approved_Status_Updated_On}</td>
                                                    </tr>
                                                }
                                                {TicketDetails?.Ticket_Approved_Status_Updated_By &&
                                                    <tr>
                                                        <th scope="row">Ticket Approved Status Updated By</th>
                                                        <td>{TicketDetails?.Ticket_Approved_Status_Updated_By?.Email} - {TicketDetails?.Ticket_Approved_Status_Updated_By?.TypeOfUser}</td>
                                                    </tr>
                                                }
                                                {(TicketDetails?.Cancellation_Charges >= 0 && TicketDetails?.Cancellation_Charges >= 0 && TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer'
                                                    && TicketDetails?.Ticket_Approved_Status === 'Approved') ?
                                                    <tr>
                                                        <th scope="row">Cancellation Charges</th>
                                                        <td className='font-weight-bold'>Rs. {float2D(TicketDetails?.Cancellation_Charges)}</td>
                                                    </tr> : <></>
                                                }
                                                {(TicketDetails?.Difference_In_Price >= 0 && TicketDetails?.CauseOfTicket === 'Change Requests' && TicketDetails?.Ticket_Approved_Status === 'Approved') &&
                                                    <tr>
                                                        <th scope="row">Difference in Price</th>
                                                        <td className='font-weight-bold'>Rs. {float2D(TicketDetails?.Difference_In_Price)}</td>
                                                    </tr>
                                                }
                                                {TicketDetails?.Refund_Status &&
                                                    <>
                                                        {TicketDetails?.Total_Amount &&
                                                            <tr>
                                                                <th scope="row">Total Amount</th>
                                                                <td>Rs. {float2D(TicketDetails?.Total_Amount)}</td>
                                                            </tr>
                                                        }
                                                        {TicketDetails?.Refund_Amount &&
                                                            <tr>
                                                                <th scope="row">Refund Amount</th>
                                                                <td>Rs. {float2D(TicketDetails?.Refund_Amount)}</td>
                                                            </tr>
                                                        }
                                                        {TicketDetails?.RefundType === 'Coupon' &&
                                                            <>
                                                                <tr>
                                                                    <th scope="row">Coupon Type</th>
                                                                    <td>{TicketDetails?.CouponType}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Coupon Value</th>
                                                                    <td>{TicketDetails?.CouponType === 'Percentage' ? TicketDetails?.CouponValue + '%' : 'Rs. ' + float2D(TicketDetails?.CouponValue)}</td>
                                                                </tr>
                                                            </>
                                                        }

                                                        {TicketDetails?.Refund_Status &&
                                                            <tr>
                                                                <th scope="row">Refund Status</th>
                                                                <td className='font-weight-bold'>{TicketDetails?.Refund_Status}</td>
                                                            </tr>
                                                        }
                                                        {TicketDetails?.Refund_On &&
                                                            <tr>
                                                                <th scope="row">Refund On</th>
                                                                <td>{TicketDetails?.Refund_On}</td>
                                                            </tr>
                                                        }
                                                        {TicketDetails?.Refund_By &&
                                                            <tr>
                                                                <th scope="row">Refund By</th>
                                                                <td>{TicketDetails?.Refund_By?.Name} - {TicketDetails?.Refund_By?.Email}</td>
                                                            </tr>
                                                        }
                                                    </>
                                                }
                                                {TicketDetails?.Payment_Intimated_On &&
                                                    <>
                                                        <tr>
                                                            <th scope="row">Customer Approved Status</th>
                                                            <td><span className={(TicketDetails?.Customer_Approved_Status?.toUpperCase())?.replace(/ /g, '')}>{TicketDetails?.Customer_Approved_Status}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Customer Paid Status</th>
                                                            <td>{TicketDetails?.Customer_Paid_Status}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Payment Type</th>
                                                            <td>{TicketDetails?.PaymentType}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Payment Link Sent By</th>
                                                            <td>{TicketDetails?.Payment_Intimated_By?.Name} - {TicketDetails?.Payment_Intimated_By?.Email}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Payment Link Sent On</th>
                                                            <td>{TicketDetails?.Payment_Intimated_On}</td>
                                                        </tr>
                                                    </>
                                                }
                                                {(TicketDetails?.Ticket_Solutions && TicketDetails?.Ticket_Solutions?.length > 0) &&
                                                    <TicketSolution Ticket_Solutions={TicketDetails?.Ticket_Solutions} />
                                                }
                                                {TicketDetails?.HelpdeskC_Notes &&
                                                    <tr>
                                                        <th scope="row">Helpdesk C Notes</th>
                                                        <td>{TicketDetails?.HelpdeskC_Notes}</td>
                                                    </tr>
                                                }
                                                {TicketDetails?.Ticket_Status === 'Closed' &&
                                                    <>
                                                        <tr>
                                                            <th scope="row">Ticket Closed By</th>
                                                            <td>{TicketDetails?.Ticket_Closed_By?.Name} - {TicketDetails?.Ticket_Closed_By?.Email}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Ticket Closed On</th>
                                                            <td>{TicketDetails?.Ticket_Closed_On}</td>
                                                        </tr>
                                                    </>
                                                }
                                                {TicketDetails?.Manager_Approved_Status &&
                                                    <>
                                                        <tr>
                                                            <th scope="row">Manager Approved Status</th>
                                                            <td><span className={(TicketDetails?.Manager_Approved_Status?.toUpperCase())?.replace(/ /g, '')}>{TicketDetails?.Manager_Approved_Status}</span></td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">Manager Approved Status Updated On</th>
                                                            <td>{TicketDetails?.Manager_Approved_Status_Updated_On}</td>
                                                        </tr>
                                                    </>
                                                }
                                                {TicketDetails?.HelpdeskC_Remarks &&
                                                    <tr>
                                                        <th scope="row">Customer Helpdesk Remarks</th>
                                                        <td>{TicketDetails?.HelpdeskC_Remarks}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {TicketDetails?.Final_Solution &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">FINAL SOLUTION</h4>
                                        <hr />
                                        <div className="ms-panel">
                                            <div className="ms-panel-body tabledata-background">
                                                <div>
                                                    <span className='font-weight-bold mr-1'>Solution: </span>
                                                    <span>{TicketDetails?.Final_Solution?.Solution}</span>
                                                </div>
                                                <div>
                                                    <span className='font-weight-bold mr-1 mt-2'>Entered By: </span>
                                                    <span> {TicketDetails?.Final_Solution?.Solution_Entered_By?.Name} - {TicketDetails?.Final_Solution?.Solution_Entered_By?.Email}</span>
                                                </div>
                                                <span className='font-weight-bold mr-1 mt-2'>Entered On: </span>
                                                <span> {TicketDetails?.Final_Solution?.Solution_Entered_On}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">TICKET DESCRIPTION</h4>
                                    <hr />
                                    <p>{TicketDetails?.TicketDescription}</p>
                                    {(TicketDetails?.CauseOfTicket !== 'Vendor Request' && !TicketDetails?.Accounts_Accepted_By && TicketDetails?.Ticket_Status !== 'Closed') &&
                                        <button
                                            className="btn btn-success float-start mr-3"
                                            type="submit"
                                            onClick={handleAcceptTicket}
                                        >
                                            Accept
                                        </button>
                                    }
                                    {(TicketDetails?.Accounts_Accepted_By && TicketDetails?.Accounts_Accepted_By?.Email === auth?.token?.result?.Email &&
                                        TicketDetails?.Last_Intimate?.includes('Accounts') && (TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' || TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor') &&
                                        (!TicketDetails?.Refund_Status || (TicketDetails?.Refund_Status === 'Pending'))) &&
                                        <button
                                            className="btn btn-primary float-start mr-3"
                                            type="submit"
                                            onClick={handleRefundPopup}
                                        >
                                            {(TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor' && TicketDetails?.Ticket_Approved_Status === 'Cancel and Refund Coupon') ?
                                                "Generate Coupon" : "Refund"}
                                        </button>
                                    }
                                    {(TicketDetails?.Accounts_Accepted_By && TicketDetails?.Accounts_Accepted_By?.Email === auth?.token?.result?.Email &&
                                        TicketDetails?.Last_Intimate?.includes('Accounts') && TicketDetails?.CauseOfTicket === 'Change Requests' &&
                                        !TicketDetails?.Payment_Intimated_On) &&
                                        <button
                                            className="btn btn-primary float-start mr-3"
                                            type="submit"
                                            onClick={handlePaymentListPopup}
                                        >
                                            {(OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() === 'cash on delivery' ||
                                                (TicketDetails?.Ticket_Approved_Status === 'Approved' && TicketDetails?.Difference_In_Price === 0)) ?
                                                'Intimate Customer' : 'Send Payment Link'}
                                        </button>
                                    }
                                    {(TicketDetails?.Accounts_Accepted_By && TicketDetails?.Accounts_Accepted_By?.Email === auth?.token?.result?.Email &&
                                        TicketDetails?.Last_Intimate?.includes('Accounts') && (TicketDetails?.CauseOfTicket === 'Cancellation Within 15mins') &&
                                        (!TicketDetails?.Refund_Status)) &&
                                        <button
                                            className="btn btn-primary float-start mr-3"
                                            type="submit"
                                            onClick={handleRefundFor15MinsCancelPopup}
                                        >
                                            Refund
                                        </button>
                                    }
                                    {(TicketDetails?.Accounts_Accepted_By && TicketDetails?.Accounts_Accepted_By?.Email === auth?.token?.result?.Email &&
                                        TicketDetails?.Last_Intimate?.includes('Accounts') && (TicketDetails?.CauseOfTicket === 'Miscellaneous') &&
                                        (TicketDetails?.Refund_Status === 'Pending')) &&
                                        <button
                                            className="btn btn-primary float-start mr-3"
                                            type="submit"
                                            onClick={handleRefundForMiscellaneousPopup}
                                        >
                                            Refund
                                        </button>
                                    }
                                    {/* {(TicketDetails?.Accounts_Accepted_By && TicketDetails?.Accounts_Accepted_By?.Email === auth?.token?.result?.Email &&
                                        TicketDetails?.Last_Intimate?.includes('Accounts') && (TicketDetails?.CauseOfTicket === 'Cancellation Within 15mins' && TicketDetails?.Refund_Status)) &&
                                        <button
                                            className="btn btn-success float-start mr-3"
                                            type="submit"
                                            onClick={handleIntimatePopup}
                                        >
                                            Intimate
                                        </button>
                                    } */}
                                    {(TicketDetails?.Accounts_Accepted_By && TicketDetails?.Accounts_Accepted_By?.Email === auth?.token?.result?.Email &&
                                        TicketDetails?.Last_Intimate?.includes('Accounts') && (TicketDetails?.CauseOfTicket !== 'Change Requests' && TicketDetails?.CauseOfTicket !== 'Cancellation Within 15mins' && TicketDetails?.CauseOfTicket !== 'Order Cancellation by Customer' &&
                                            TicketDetails?.CauseOfTicket !== 'Order Cancellation by Vendor' && TicketDetails?.CauseOfTicket !== 'Vendor Request' && TicketDetails?.CauseOfTicket !== 'Miscellaneous')) &&
                                        <button
                                            className="btn btn-success float-start mr-3"
                                            type="submit"
                                            onClick={handleIntimatePopup}
                                        >
                                            Intimate
                                        </button>
                                    }
                                    {(TicketDetails?.CauseOfTicket === 'Vendor Request' && !TicketDetails?.Final_Solution) &&
                                        <button
                                            className="btn btn-primary float-start mr-3"
                                            type="submit"
                                            onClick={handleEnterSolutionPopup}
                                        >
                                            Enter Solution
                                        </button>
                                    }
                                    <button
                                        className='btn btn-secondary float-start'
                                        type="submit"
                                        onClick={handleCloseDetails}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrderDetailsPopup
                        handleCloseDetailsPopup={handleCloseDetailsPopup}
                        showOrderDetailsPopup={showOrderDetailsPopup}
                        OrderDetailsByTypeOfOrder={OrderDetailsByTypeOfOrder}
                        TypeOfOrder={TicketDetails?.TypeOfOrder}
                    />
                    <UserDetailsPopup
                        handleCloseDetailsPopup={handleCloseDetailsPopup} showUserDetailsPopup={showUserDetailsPopup}
                    />
                    <RefundPopup
                        showRefundPopup={showRefundPopup} loader={loader} CouponCodeList={CouponCodeList}
                        handleCloseRefundPopup={handleCloseRefundPopup}
                        OrderDetailsByTypeOfOrder={OrderDetailsByTypeOfOrder}
                        TicketDetails={TicketDetails} handleRefundPayment={handleRefundPayment}
                    />
                    <TicketIntimationPopup
                        showIntimateListPopup={showIntimateListPopup}
                        handleCloseIntimatePopup={handleCloseIntimatePopup}
                        auth={auth} loader={loader} enableIntimate={enableIntimate}
                        handleChangedAccess={handleChangedAccess}
                        handleUpdateIntimate={handleUpdateIntimate}
                    />
                    <SendPaymentLinkPopup
                        showPaymentListPopup={showPaymentListPopup} TicketDetails={TicketDetails}
                        handleClosePaymentLinkPopup={handleClosePaymentLinkPopup}
                        handleSendPaymentLink={handleSendPaymentLink} loader={loader}
                        OrderDetailsByTypeOfOrder={OrderDetailsByTypeOfOrder}
                    />
                    <VendorDetailsPopup
                        showVendorDetailsPopup={showVendorDetailsPopup} VendorDetails={VendorDetails}
                        handleCloseDetailsPopup={handleCloseDetailsPopup}
                    />
                    <RefundFor15MinsCancelPopup
                        showRefundFor15MinsCancelPopup={showRefundFor15MinsCancelPopup}
                        OrderDetailsByTypeOfOrder={OrderDetailsByTypeOfOrder} handleCloseRefundFor15MinsCancelPopup={handleCloseRefundFor15MinsCancelPopup}
                        handleRefundPaymentFor15MinsCancel={handleRefundPaymentFor15MinsCancel} loader={loader}
                    />
                    <EnterSolutionPopup
                        showEnterSolutionPopup={showEnterSolutionPopup} handleCloseEnterSolutionPopup={handleCloseEnterSolutionPopup}
                        handleUpdateSolution={handleUpdateSolution} loader={loader}
                    />
                    <RefundForMiscellaneousPopup
                        showRefundForMiscellaneousPopup={showRefundForMiscellaneousPopup}
                        handleCloseRefundFor15MinsCancelPopup={handleCloseRefundFor15MinsCancelPopup}
                        CouponCodeList={CouponCodeList} OrderDetailsByTypeOfOrder={OrderDetailsByTypeOfOrder}
                        TicketDetails={TicketDetails} loader={loader}
                        handleRefundPaymentForMiscellaneous={handleRefundPaymentForMiscellaneous}
                    />
                </div>
            }
        </>
    )
};

function TicketSolution({ Ticket_Solutions }) {
    return (
        <div>
            <hr />
            {Ticket_Solutions.map((val, i) =>
                <div className="col-xl-12 col-md-12" key={i}>
                    <div className="ms-panel">
                        <div className="ms-panel-body tabledata-background">
                            <div>
                                <span className='font-weight-bold mr-1'>Solution: </span>
                                <span>{val.Solution}</span>
                            </div>
                            <div>
                                <span className='font-weight-bold mr-1 mt-2'>Entered By: </span>
                                <span> {val.Solution_Entered_By?.Name} - {val.Solution_Entered_By?.Email}</span>
                            </div>
                            <span className='font-weight-bold mr-1 mt-2'>Entered On: </span>
                            <span> {val.Solution_Entered_On}</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};