import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import AccountsActions from '../../../../redux/Accounts/actions';
import OrderActions from '../../../../redux/Order/actions';
import Preloader from '../../../layouts/Preloader';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import { CSVLink } from 'react-csv';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import { float2D } from '../../../../utils/Validation';

export default function Content() {

    const dispatch = useDispatch();
    const state = useLocation();

    const Id = state?.state?.Id;

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [show, setShow] = useState(false);
    const [date, setDate] = useState("");
    const [payment, setPayment] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [paymentDate, setPaymentDate] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [exportStatement, setExportStatement] = useState([]);

    const { VendorStatementDetails } = useSelector(state => state.AccountsReducer);

    const { CreateStatementStatus } = useSelector(state => state.OrderReducer);

    useEffect(() => {
        const Month = moment().format('DD-MM-YYYY');
        setDate(moment().format('YYYY-MM'));
        dispatch({
            type: AccountsActions.GET_VENDOR_STATEMENTOFACCOUNTS_DETAILS,
            payload: { Id: Id, Month: Month?.slice(3, 5), Year: Month?.slice(6, 10), TypeOfUser: "Admin" }
        });
        // eslint-disable-next-line
    }, [Id]);

    //validate the list is empty or not
    useEffect(() => {
        if (!VendorStatementDetails) {
            setFilteredData([]);
        } else {
            setFilteredData(VendorStatementDetails.result)
        };
    }, [VendorStatementDetails]);

    const handleTableSearch = (e) => {
        setDate(moment(e.target.value).format('YYYY-MM'));
        dispatch({
            type: AccountsActions.GET_VENDOR_STATEMENTOFACCOUNTS_DETAILS,
            payload: { Id: Id, Month: e.target.value.slice(5, 7), Year: e.target.value.slice(0, 4), TypeOfUser: "Admin" }
        });
    };

    const handleClose = (e) => {
        e.preventDefault();
        setShow(false);
    };

    const handleCreateStatement = (e) => {
        e.preventDefault();
        if (payment && paymentType && paymentDate) {
            const Month = moment().format('DD-MM-YYYY');
            const data = {
                VendorID: Id,
                Payment: payment,
                PaymentType: paymentType,
                Payment_Date: paymentDate.split("-").reverse().join("-"),
            };
            setLoader(true);
            dispatch({
                type: OrderActions.CREATE_STATEMENT,
                payload: {
                    data: data,
                    VendorID: Id,
                    Month: Month.slice(3, 5),
                    Year: Month.slice(6, 10)
                }
            });
            setDate(moment().format('YYYY-MM'));
            setPayment("");
            setPaymentType("");
            setPaymentDate("");
        } else {
            setError("*required");
        };
    };

    const handleExport = () => {
        const Statement = [
            [
                "Date",
                "Order ID",
                "Description",
                "Product Price",
                "Cakey Share",
                "Discount",
                "Delivery Charges",
                "Total Payment",
                "Payment",
                "Pending Payment",
            ],
            [
                " ", " ", " ", " ", " ", " ", " ", " ", "Opening Balance:", float2D(VendorStatementDetails?.OpeningBalance)
            ],
            [
                " ", " ", " ", " ", " ", " ", " ", " ", " ", " "
            ],
            ...filteredData?.map(details =>

                details.Type === 'Payment' ?
                    [
                        details.Date,
                        "-",
                        details.Type,
                        "-",
                        "-",
                        "-",
                        "-",
                        "-",
                        float2D(details.FinalTotal),
                        "-"
                    ] :
                    [
                        details.Date,
                        details.Order_ID,
                        details.Type,
                        details.Total,
                        details.CakeyShare,
                        details.Discount,
                        details.DeliveryCharge,
                        float2D(details.FinalTotal),
                        "-",
                        float2D(details.FinalTotal)
                    ]
            ),
            [
                " ", " ", " ", " ", " ", " ", " ", " ", " ", " "
            ],
            [
                " ", " ", " ", VendorStatementDetails?.TotalCalculations?.Total, VendorStatementDetails?.TotalCalculations?.CakeyShare, VendorStatementDetails?.TotalCalculations?.Discount, VendorStatementDetails?.TotalCalculations?.DeliveryCharge, VendorStatementDetails?.TotalCalculations?.FinalTotal, VendorStatementDetails?.TotalCalculations?.FinalPayment, " "
            ],
            [
                " ", " ", " ", " ", " ", " ", " ", " ", " ", " "
            ],
            [
                " ", " ", " ", " ", " ", " ", " ", " ", "Pending Payment:", float2D(VendorStatementDetails?.ClosingBalance)
            ],
        ]
        Statement.reduce((prev, curr) => [prev, curr]);
        const data = Statement;
        setExportStatement(data);
    };

    useEffect(() => {
        if (CreateStatementStatus?.statusCode === 200 || CreateStatementStatus?.statusCode === 400) {
            setLoader(false);
            setShow(false);
        };
    }, [CreateStatementStatus]);

    //loading
    useEffect(() => {
        if (VendorStatementDetails.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
        // eslint-disable-next-line
    }, [VendorStatementDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Statement Of Accounts ({state?.state?.Vendor_ID} - {state?.state?.Name})</h6>
                                    <span className="float-right add-banner-button">
                                        <button className="btn btn-success add-button rounded" type="submit" onClick={() => setShow(true)}>Create Payment</button>
                                        {filteredData?.length === 0 ?
                                            <button className="btn btn-success add-button rounded text-white font-weight-bold" disabled type="submit">EXPORT</button> :
                                            <button className="btn btn-success add-button rounded" type="submit">
                                                <CSVLink
                                                    data={exportStatement}
                                                    filename={`${state?.state?.Name}'s account statement for ${date.slice(5, 7)} - ${date.slice(0, 4)}.csv`}
                                                    className="text-white font-weight-bold"
                                                    target="_blank"
                                                    onClick={handleExport}
                                                >
                                                    EXPORT
                                                </CSVLink>
                                            </button>
                                        }
                                    </span>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row ">
                                                <div className="col-sm-12 col-md-6"></div>
                                                <div className="col-sm-12 col-md-6 mb-2">
                                                    <div id="data-table-4_filter" className="dataTables_filter">
                                                        <label className="font-weight-bold">Select Month :
                                                            <input
                                                                type='month'
                                                                max={moment().format('YYYY-MM')}
                                                                className="form-control form-control-sm"
                                                                value={date || ""}
                                                                aria-controls="data-table-4"
                                                                onChange={handleTableSearch}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Date</th>
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Description</th>
                                                                <th scope="col">Product Price</th>
                                                                {/* <th scope="col">Coupon Amount</th> */}
                                                                <th scope="col">Cakey Share</th>
                                                                {/* newly added field */}
                                                                <th scope="col">Discount</th>
                                                                <th scope="col">Delivery Charges</th>
                                                                <th scope="col">Total Payment</th>
                                                                <th scope="col">Payment</th>
                                                                <th scope="col"> Pending Payment</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="odd">
                                                                {/* <td colSpan={7}></td> */}
                                                                <td colSpan={8}></td>
                                                                <td className='font-weight-bold'>Opening Balance</td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.OpeningBalance || 0)}</td>
                                                            </tr>
                                                            {filteredData?.length > 0 ?
                                                                <>
                                                                    {filteredData.map((order) =>
                                                                        <> {order.Type === 'Payment' ?
                                                                            <tr className="odd" >
                                                                                <th className='id-column' scope="row">{order.Date}</th>
                                                                                <td className="vendor-details-alignment">----</td>
                                                                                <td className='font-weight-bold'>{order.Type}</td>
                                                                                <td className="vendor-details-alignment">----</td>
                                                                                {/* <td className="vendor-details-alignment">----</td> */}
                                                                                <td className="vendor-details-alignment" >----</td>
                                                                                <td className="vendor-details-alignment" >----</td>
                                                                                <td className="vendor-details-alignment">----</td>
                                                                                <td className="vendor-details-alignment">----</td>
                                                                                <td className="vendor-details-alignment">{float2D(order.FinalTotal)}</td>
                                                                                <td className="vendor-details-alignment">----</td>
                                                                            </tr> :
                                                                            <tr className="odd">
                                                                                <th className='id-column' scope="row">{order.Date}</th>
                                                                                <td>{order.Order_ID}</td>
                                                                                <td>{order.Type}</td>
                                                                                <td className="vendor-details-alignment">{float2D(order.Total)}</td>
                                                                                {/* <td className="vendor-details-alignment">{float2D(order.CouponValue || 0.00)}</td> */}
                                                                                <td className="vendor-details-alignment">{float2D(order.CakeyShare)}</td>
                                                                                <td className="vendor-details-alignment">{float2D(order.Discount)}</td>
                                                                                <td className="vendor-details-alignment">{float2D(order.DeliveryCharge)}</td>
                                                                                <td className="vendor-details-alignment">{float2D(order.FinalTotal)}</td>
                                                                                <td className="vendor-details-alignment">----</td>
                                                                                <td className="vendor-details-alignment">{float2D(order.FinalTotal)}</td>
                                                                            </tr>
                                                                        }
                                                                        </>
                                                                    )}
                                                                </> :
                                                                <tr>
                                                                    {/* <td colSpan={9}>----</td> */}
                                                                    <td colSpan={10}>----</td>
                                                                </tr>
                                                            }
                                                            <tr className="odd">
                                                                <td colSpan={3}></td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.TotalCalculations?.Total)} </td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.TotalCalculations?.CakeyShare)} </td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.TotalCalculations?.Discount)} </td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.TotalCalculations?.DeliveryCharge)} </td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.TotalCalculations?.FinalTotal)} </td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.TotalCalculations?.FinalPayment)} </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr className="odd">
                                                                {/* <td colSpan={7}></td> */}
                                                                <td colSpan={8}></td>
                                                                <td className='font-weight-bold'>Total Pending Amount </td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.ClosingBalance || 0)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal className="modal-min" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center pb-2">Create Statement - {state?.state?.Name}</h1>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Amount Due</label>
                                    <div className="input-group">
                                        <button className="RupeeSign" disabled>Rs.</button>
                                        <input
                                            type="text"
                                            className="form-control font-weight-bold"
                                            disabled value={VendorStatementDetails?.ClosingBalance}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Payment <span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        <button className="RupeeSign" disabled>Rs.</button>
                                        <input
                                            type="text"
                                            className="form-control"
                                            min={0}
                                            maxLength={10}
                                            onInput={(e) => {
                                                if (e.target.value.length > e.target.maxLength) {
                                                    e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                } else if (e.target.value < e.target.min) {
                                                    e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                }
                                            }}
                                            placeholder='e.g. 1500'
                                            value={payment || ""}
                                            onChange={(e) => setPayment(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Payment Type <span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        <select
                                            className="form-control"
                                            defaultValue=''
                                            value={paymentType || ""}
                                            onChange={(e) => setPaymentType(e.target.value)}
                                        >
                                            <option disabled value=''>--Select--</option>
                                            <option value='UPI'>UPI</option>
                                            <option value='Cash'>Cash</option>
                                            <option value='Bank Transfer'>Bank Transfer</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Payment Date <span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        <input
                                            type="date"
                                            className="form-control"
                                            max={moment().format('YYYY-MM-DD')}
                                            value={paymentDate || ""}
                                            onChange={(e) => setPaymentDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {loader ?
                                <div className="login-spinner2"><CircleDotSpinner /></div> :
                                <>
                                    <p className='text-danger'>{error}</p>
                                    <button
                                        type="submit"
                                        className="btn btn-primary ml-5"
                                        onClick={handleCreateStatement}
                                    >
                                        Create
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-secondary ml-5"
                                        onClick={() => setShow(false)}
                                    >
                                        Cancel
                                    </button>
                                </>
                            }
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    )
};