import React, { useEffect, useState } from "react";
import Breadcrumb from "./Breadcrumb";
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import Preloader from "../../layouts/Preloader";
import OtherActions from '../../../redux/OtherProducts/actions';
// import OrderActions from '../../../redux/Order/actions';
import Flavour from "../../vendor/section/Vendor-Other-Product-Order-Details/Flavour";
import { float2D } from "../../../utils/Validation";
import { ResponsiveEmbed } from "react-bootstrap";

export default function Details() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.Id;

    const [fetchSuccess, setFetchSuccess] = useState(false);

    const { OtherProductOrderDetails } = useSelector(state => state.OtherProductReducer);
    // const OtherProductOrders = useSelector(state => state.OtherProductReducer);

    //dispatch the get orders list api
    useEffect(() => {
        dispatch({ type: OtherActions.GET_OTHER_PRODUCT_ORDER_DETAILS, payload: Id });
        // eslint-disable-next-line
    }, [Id]);

    //close the page
    const handleCancel = (e) => {
        e.preventDefault();
        history.push("/Admin-product-orders-list");
    };

    // const handleChangeStatus = (e) => {
    //     e.preventDefault();
    //     dispatch({
    //         type: OrderActions.CHANGE_OTHERPRODUCT_ORDER_VIEW_RESPONSE,
    //         payload: { id: OtherProductOrderDetails?._id, response: 'seen' }
    //     });
    // };

    //loading
    useEffect(() => {
        if (OtherProductOrderDetails.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
    }, [OtherProductOrderDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Product Order Details</h6>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                {OtherProductOrderDetails?.Image &&
                                    <div className="ms-panel-body order-image tabledata-background">
                                        <ResponsiveEmbed aspectRatio='21by9'>
                                            <img
                                                className="d-block align-center responsive-image-width"
                                                src={OtherProductOrderDetails?.Image}
                                                alt="Cake"
                                            />
                                        </ResponsiveEmbed>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRODUCT INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {(OtherProductOrderDetails?.ReasonForCancel && (OtherProductOrderDetails?.Status === 'Rejected' || OtherProductOrderDetails?.Status === 'Cancelled')) &&
                                                <tr className="text-danger">
                                                    <th scope="row">Reason for Cancel</th>
                                                    <td>{OtherProductOrderDetails?.ReasonForCancel}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Order ID</th>
                                                <td><b>{OtherProductOrderDetails?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product ID</th>
                                                <td><b>{OtherProductOrderDetails?.Other_Product_ID}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product Name</th>
                                                <td>{OtherProductOrderDetails?.ProductName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product Common Name</th>
                                                <td>{OtherProductOrderDetails?.ProductCommonName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product Type</th>
                                                <td>{OtherProductOrderDetails?.CakeType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product SubType</th>
                                                <td><b>{OtherProductOrderDetails?.CakeSubType === 'macaroons' ? 'Macarons' : OtherProductOrderDetails?.CakeSubType}</b></td>
                                            </tr>
                                            {OtherProductOrderDetails?.EggOrEggless &&
                                                <tr>
                                                    <th scope="row">Egg or Eggless</th>
                                                    <td>{OtherProductOrderDetails?.EggOrEggless}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Flavour</th>
                                                <td><Flavour FlavoursList={OtherProductOrderDetails?.Flavour} /></td>
                                            </tr>
                                            {OtherProductOrderDetails?.Shape &&
                                                <tr>
                                                    <th scope="row">Shape</th>
                                                    <td>{OtherProductOrderDetails?.Shape}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Ordered Date</th>
                                                <td>{OtherProductOrderDetails?.Created_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(OtherProductOrderDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{OtherProductOrderDetails?.Status}</span></td>
                                            </tr>
                                            {OtherProductOrderDetails?.Status_Updated_On ?
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{OtherProductOrderDetails?.Status_Updated_On}</td>
                                                </tr> :
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{OtherProductOrderDetails?.Created_On}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {OtherProductOrderDetails?.Toppers?.TopperName &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">TOPPER INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Topper Name</th>
                                                    <td>{OtherProductOrderDetails?.Toppers?.TopperName}</td>
                                                </tr>
                                                {/* {(OtherProductOrderDetails?.NewTopperCharges || OtherProductOrderDetails?.Toppers?.TopperPrice) &&
                                                    <tr>
                                                        <th scope="row">Topper Price</th>
                                                        <td>Rs. {float2D(OtherProductOrderDetails?.NewTopperCharges) ? float2D(OtherProductOrderDetails?.NewTopperCharges) : float2D(OtherProductOrderDetails?.Toppers?.TopperPrice)}</td>
                                                    </tr>
                                                } */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="ms-panel-body tabledata-background">
                                        <ResponsiveEmbed aspectRatio='21by9'>
                                            <img
                                                className="d-block align-center w-50 h-50 responsive-image-width"
                                                src={OtherProductOrderDetails?.Toppers?.TopperImage}
                                                alt="First slide"
                                            />
                                        </ResponsiveEmbed>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRICE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {OtherProductOrderDetails?.ItemCount &&
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{OtherProductOrderDetails?.ItemCount}</td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerKg?.Weight &&
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{OtherProductOrderDetails?.ProductMinWeightPerKg?.Weight}</td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerKg?.PricePerKg &&
                                                <tr>
                                                    <th scope="row">Price per Kg</th>
                                                    <td><span>{float2D(OtherProductOrderDetails?.NewProductMinWeightPerKg?.PricePerKg ? OtherProductOrderDetails?.NewProductMinWeightPerKg?.PricePerKg : OtherProductOrderDetails?.ProductMinWeightPerKg?.PricePerKg)}</span></td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerUnit?.Weight &&
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{OtherProductOrderDetails?.ProductMinWeightPerUnit?.Weight}</td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerUnit?.ProductCount &&
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{OtherProductOrderDetails?.ProductMinWeightPerUnit?.ProductCount} Units</td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerUnit?.PricePerUnit &&
                                                <tr>
                                                    <th scope="row">Price per Unit</th>
                                                    <td><span>{float2D(OtherProductOrderDetails?.NewProductMinWeightPerUnit?.PricePerUnit ? OtherProductOrderDetails?.NewProductMinWeightPerUnit?.PricePerUnit : OtherProductOrderDetails?.ProductMinWeightPerUnit?.PricePerUnit)}</span></td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerBox?.Piece &&
                                                <tr>
                                                    <th scope="row">Pieces per Box</th>
                                                    <td>{OtherProductOrderDetails?.ProductMinWeightPerBox?.Piece} Pieces</td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerBox?.ProductCount &&
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{OtherProductOrderDetails?.ProductMinWeightPerBox?.ProductCount} Box</td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.ProductMinWeightPerBox?.PricePerBox &&
                                                <tr>
                                                    <th scope="row">Price per Box</th>
                                                    <td><span>{float2D(OtherProductOrderDetails?.NewProductMinWeightPerBox?.PricePerBox ? OtherProductOrderDetails?.NewProductMinWeightPerBox?.PricePerBox : OtherProductOrderDetails?.ProductMinWeightPerBox?.PricePerBox)}</span></td>
                                                </tr>
                                            }
                                            {(OtherProductOrderDetails?.NewTopperCharges || OtherProductOrderDetails?.Toppers?.TopperPrice) &&
                                                <tr>
                                                    <th scope="row">Topper Price</th>
                                                    <td>{float2D(OtherProductOrderDetails?.NewTopperCharges) ? float2D(OtherProductOrderDetails?.NewTopperCharges) : float2D(OtherProductOrderDetails?.Toppers?.TopperPrice)}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td><span>{float2D(OtherProductOrderDetails?.NewDiscount ? OtherProductOrderDetails?.NewDiscount : OtherProductOrderDetails?.Discount)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Charge</th>
                                                <td><span>{float2D(OtherProductOrderDetails?.NewDeliveryCharges ? OtherProductOrderDetails?.NewDeliveryCharges : OtherProductOrderDetails?.DeliveryCharge)}</span></td>
                                            </tr>
                                            {/* <tr>
                                                <th scope="row">Discount</th>
                                                <td><span>{float2D(OtherProductOrderDetails?.NewDiscount ? OtherProductOrderDetails?.NewDiscount : OtherProductOrderDetails?.Discount)}</span></td>
                                            </tr> */}
                                            {(OtherProductOrderDetails?.CouponValue && !OtherProductOrderDetails?.NewTotal) &&
                                                <tr>
                                                    <th scope="row">Coupon Value</th>
                                                    <td><span>{float2D(OtherProductOrderDetails?.CouponValue || 0)}</span></td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">CGST</th>
                                                <td><span>{float2D(OtherProductOrderDetails?.NewTax ? (OtherProductOrderDetails?.NewTax / 2) : OtherProductOrderDetails?.Gst)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">SGST</th>
                                                <td><span>{float2D(OtherProductOrderDetails?.NewTax ? (OtherProductOrderDetails?.NewTax / 2) : OtherProductOrderDetails?.Sgst)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Total</th>
                                                <td><span><b>{float2D(OtherProductOrderDetails?.NewTotal ? OtherProductOrderDetails?.NewTotal : OtherProductOrderDetails?.Total)}</b></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {OtherProductOrderDetails?.FinalProductImage &&
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h6 className='text-uppercase font-weight-bold'>FINAL PRODUCT IMAGE</h6>
                                    </div>
                                    <div className='header-break-line'> <hr /> </div>
                                    <div className="col-md-12">
                                        <div className="ms-panel">
                                            <div className="ms-panel-body order-image tabledata-background">
                                                <ResponsiveEmbed aspectRatio='21by9'>
                                                    <img
                                                        className="d-block align-center responsive-image-width"
                                                        src={OtherProductOrderDetails?.FinalProductImage}
                                                        alt="Cake"
                                                    />
                                                </ResponsiveEmbed>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">DELIVERY INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Delivery Information</th>
                                                <td>{OtherProductOrderDetails?.DeliveryInformation}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Date</th>
                                                <td>{OtherProductOrderDetails?.DeliveryDate}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Session</th>
                                                <td>{OtherProductOrderDetails?.DeliverySession}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Type</th>
                                                <td>{OtherProductOrderDetails?.PaymentType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Status</th>
                                                <td>{OtherProductOrderDetails?.PaymentStatus}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CUSTOMER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Customer ID</th>
                                                <td>{OtherProductOrderDetails?.User_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Name</th>
                                                <td>{OtherProductOrderDetails?.UserName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Mobile Number</th>
                                                <td>{OtherProductOrderDetails?.UserPhoneNumber}</td>
                                            </tr>
                                            {OtherProductOrderDetails?.DeliveryAddress &&
                                                <tr>
                                                    <th scope="row">Delivery Address</th>
                                                    <td>{OtherProductOrderDetails?.DeliveryAddress}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">VENDOR INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Vendor ID</th>
                                                <td>{OtherProductOrderDetails?.Vendor_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Name</th>
                                                <td>{OtherProductOrderDetails?.VendorName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Main Mobile Number</th>
                                                <td>{OtherProductOrderDetails?.VendorPhoneNumber1}</td>
                                            </tr>
                                            {OtherProductOrderDetails?.VendorPhoneNumber2 &&
                                                <tr>
                                                    <th scope="row">Vendor Alternative Mobile Number</th>
                                                    <td>{OtherProductOrderDetails?.VendorPhoneNumber2}</td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.VendorAddress &&
                                                <tr>
                                                    <th scope="row">Vendor Address</th>
                                                    <td><span className="long-line-break">{OtherProductOrderDetails?.VendorAddress}</span></td>
                                                </tr>
                                            }
                                            {OtherProductOrderDetails?.GoogleLocation &&
                                                <tr>
                                                    <th scope="row">Google Location</th>
                                                    <td>
                                                        <b>Latitude</b> : {OtherProductOrderDetails?.GoogleLocation?.Latitude} <br />
                                                        <b>Longitude</b> : {OtherProductOrderDetails?.GoogleLocation?.Longitude}
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRODUCT DESCRIPTION</h4>
                                    <hr />
                                    <p>{OtherProductOrderDetails?.Description}</p>
                                    {/* {OtherProductOrderDetails?.Vendor_Response_Status === 'no response' && OtherProductOrderDetails?.VendorID && OtherProductOrderDetails?.Status === "New" ?
                                        <button
                                            className="btn btn-primary float-start mr-3"
                                            type="submit"
                                            onClick={handleChangeStatus}
                                        >
                                            Change Status
                                        </button> :
                                        <></>
                                    } */}
                                    <button
                                        className="btn btn-secondary float-start"
                                        type="submit"
                                        onClick={handleCancel}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}